@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');


/* @import url('https://fonts.googleapis.com/css2?family=Andada+Pro:ital,wght@0,400..840;1,400..840&family=Outfit:wght@100..900&display=swap'); */

*{
    padding: 0;
    margin: 0;
    font-family: "Audiowide", sans-serif;
    font-family: "Archivo", sans-serif;
    /* font-family: "Outfit", sans-serif; */
    box-sizing: border-box;
    letter-spacing: 1px !important;
    line-height: normal !important;
}
h1, h2, h3, h4, h5, h6 {
    line-height: normal !important;
}
p+p {
    margin: initial;
}
.wrapper{
    width: 100%;
    float: left;
    padding: 0 15px 15px;
}
.splashMainDiv{
    width: 100%;
    float: left;
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 17px;
    background-image: url('../images/Background.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position-y: bottom;
}
.splashMainDiv h1{
    font-size: 42px;
    font-weight: 400;
    color: #000000;
    font-family: "Audiowide", sans-serif;
}
.splashMainDiv p{
    font-weight: 400;
    font-size: 18px;
    color: #ACACAC;
}
.adminLoginMain{
    width: 100%;
    float: left;
    background: #990000;
    /* overflow-y: scroll; */
}
.adminLoginTop{
    width: 100%;
    float: left;
    text-align: center;
    margin: 100px 0;
    padding: 0 15px;
}
.adminLoginTop h1{
    font-weight: 400;
    font-size: 42px;
    color: #fff;
    font-family: "Audiowide", sans-serif;
    padding: 0 20px 0;
}
.adminLoginTop p{
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    line-height: 25px;
    padding: 0 20px 0 20px;
}
.adminLoginBody{
    width: 100%;
    float: left;
    border-radius: 35px 35px 0 0;
    background: #fff;
    padding: 50px 20px;
    /* height: calc(100vh - 344px); */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -1px;

}

.myInput{
    width: 100%;
    height: 55px;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 0 15px;
    outline: none;
}
.myTextArea{
    width: 100%;
    height: 100px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 15px;
    outline: none;
}
.myLable{
    width: 100%;
    float: left;
    font-size: 13px;
    color: #333333;
    font-weight: 600;
    margin-bottom: 10px;
}
.errorSt{
    color: red !important;
    text-align: left;
    font-size: 13px;
    margin-top: 5px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    float: left;
    width: 100%;
}
.errorSt svg{
    font-size: 15px;
}
.adminLoginBody h1{
    font-weight: 400;
    font-size: 24px;
    color: #333333;
    text-align: center;
}
.forEyeIcon{
    position: relative;
}
.forEyeIcon .passwordEye{
    position: absolute;
    bottom: 15px;
    right: 15px;
}
.forEyeIcon .errorSt{
    position: absolute;
}
.logginBt{
    width: 100%;
    background: #990000 !important;
    height: 50px;
    border-radius: 50px !important;
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}
.doNotHave{
    width: 100%;
    float: left;
    font-weight: 500;
    font-size: 15px;
    color: #333333;
    text-align: center;
}
.doNotHave span{
    font-size: 15px;
    font-weight: 500;
    color: #990000;
}
.innerPageTopSec{
    width: 100%;
    float: left;
    padding: 15px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 55px;
    background: #fff;
    border-bottom: 1px solid #eee;
    z-index: 99;
    gap: 8px;
}
.forBackArrow{
    width: 30px;
    display: flex;
    align-items: center;
}
.innerPageTopSec h1{
    font-weight: 700;
    font-size: 16px;
    color: #000000;
    position: relative;
    z-index: 0;
    text-align: center;
    width: 100%;
}
.addEmpTpBt{
    background: #990000 !important;
    color: #fff !important;
    padding: 5px !important; 
}
.addEmpTpBt svg{

}
.listingSearch{
    width: 100%;
    float: left;
    margin-bottom: 10px;
    transition: .5s;
}
.listingSearch input{
    width: 100%;
    float: left;
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 0 15px;
    height: 40px;
    outline: none;
    color: #333;
    font-size: 15px;
}
.employyeListingMain{
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    gap: 7px;
    /* overflow: auto; */
    /* height: calc(100vh - 255px); */
}
.employyeListingInr{
    width: 100%;
    float: left;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    background: #fff;
    position: relative;
    z-index: 1;
}
.employyeLisPic{
    width: 35px;
    min-width: 35px;
    height: 35px;
    border-radius: 100%;
    overflow: hidden;
}
.employyeLisPic img{
    width: 100%;
    height: 35px;
    object-fit: cover;
}
.employyeLisCont{
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 100%;
}
.employyeLisCont h1{
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    margin-bottom: 5px;
    line-height: 20px !important;
}
.employyeLisCont span{
    font-weight: 400;
    font-size: 12px;
    color: #333333;
}
.employyeLisCont p{
    font-weight: 400;
    font-size: 11px;
    color: #8FA2B7;
}
.innerPageTopSecBackPatch{
    width: 100%;
    float: left;
    /* height: 75px; */
    height: 130px;
}
.adminBottomNavMain{
    width: 100%;
    float: left;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    border-top: 1px solid #eee;
    z-index: 3;
}
.adminBottomNavMain .Mui-selected{
    color: #990000 !important;
    font-size: 11px !important;
    font-weight: 800;
}
.adminBottomNavPatch{
    width: 100%;
    float: left;
    height: 65px;
}
.addAdminEmployeeMain{
    width: 100%;
    float: left;
}
.bodyHeadignAdmin{
    font-weight: 600;
    font-size: 17px;
    color: #333333;
}
.recentlyAdded{

}
.taskListAdmMain{
    width: 100%;
    float: left;
}
.listingSearch{
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    top: 0px;
    background: #fff;
    z-index: 9;
}
.emploTaskListInr{
    width: 100%;
    float: left;
    /* border-left: 2px solid #ccc; */
    border-bottom: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    background: #f8f8f8;
    position: relative;
    z-index: 1;
    min-height: 53px;
}
.emploTaskListInr h1{
    font-weight: 500;
    font-size: 14px;
    color: #333;
    text-align: left;
    line-height: 22px !important;
    text-transform: capitalize;
}
.emploTaskListInr h1 span{
    font-weight: 600;
    font-size: 10px;
    color: #990000;
    margin-left: 2px;
}
.emploTaskListInr p{
    font-weight: 400;
    font-size: 10px;
    color: #8FA2B7;
    line-height: 14px;
    letter-spacing: 1px;
}
.taskImUpload{
    position: relative;
}
.taskImUpload svg{
    color: #990000;
    font-size: 22px; 
}
.taskImUpload input{
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; 
}
.upperBottomBt{
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 65px;
    padding: 0 15px;
    z-index: 1;
}
.forUpperBottomBt{
    padding-bottom: 55px;
    width: 100%;
    float: left;
}
.myCheckBx .MuiTypography-root{
    font-size: 12px;
    font-weight: 400;
    color: #555555;
    letter-spacing: 1px;
}
.myCheckBx .MuiCheckbox-colorPrimary{
    color: #990000 !important;
}
.employyeLisContRight{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.employyeLisContRight span{
    font-weight: 400;
    font-size: 11px;
    color: #8FA2B7;
    margin-bottom: 5px;
}
.employyeLisContRight p{
    font-weight: 400;
    font-size: 11px;
    color: #8FA2B7;
}
.tabsMainD{
    width: 100%;
    float: left;
    position: absolute;
    left: 0;
    padding: 65px 15px 65px;
    top: 0;
    transition: .3s;
}
.tabsMainD .MuiTabs-flexContainer{
    justify-content: space-between;
}


.tabsMainD .MuiTab-textColorPrimary{
    font-size: 14px;
    font-weight: 500;
        color: #333333;
        text-transform: capitalize;
        padding: 7px 15px 10px;
        min-height: 36px;
    }


.tabsMainD .MuiBox-root{
    padding: 0;
}
.tabsMainD .Mui-selected{
    color: #990000 !important;
    font-weight: 600;
}
.tabsMainD .MuiTabs-indicator{
    background: #990000 !important;
}
.tabsMainD .MuiTabs-root{
    min-height: 25px;
    transition: .3s;
}
.tabInrContDiv{
    width: 100%;
    float: left;
    padding: 15px 0;
    /* overflow-y: scroll; */
}
.storOpeningMain{
    width: 100%;
    float: left;
}
.tabSbHeading{
    width: 100%;
    float: left;
    font-weight: 400;
    font-size: 15px;
    color: #8FA2B7;
    letter-spacing: 1px;
    text-align: left;
    margin: 10px 0;
}
.tabSbHeading b{
    color: #333333;
}
.withCheck{
    display: flex;
    align-items: center;
    gap: 10px;
}
.withCheck svg{
    color: #ccc;
    font-size: 30px !important;
}
.selectedList{
    border: 1px solid #0FB000;
    background: #F4FFEB;
}
.selectedList .withCheck svg{
    color: #0FB000;
}
.selectedList .taskImUpload svg{
    color: #0FB000;
}
.mySwitch{
    width: 100%;
    float: left;
}
.mySwitch .Mui-checked+.MuiSwitch-track{
    background-color: #990000 !important;
}
.mySwitch .Mui-checked{
    color: #990000 !important;
}
.myTxEditor{
    width: 100%;
    float: left;
}
.myTxEditor .ql-toolbar.ql-snow{
    border-radius: 10px 10px 0 0;
}
.myTxEditor .ql-container.ql-snow{
    border-radius: 0 0 10px 10px;
    height: 200px !important;
}
.myTxEditor .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    z-index: 9;
    background: #fff;
}


.mySlik{
    width: 100%;
    float: left;
}
.mySlik .slick-initialized .slick-slide {
    display: block;
    background: #F5F5F5;
    text-align: center;
    height: 50px;
    border-radius: 9px;
    display: flex;
    border: 1px solid #eee;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 16px 6px -12px rgba(0, 0, 0, 0.1);
    /* width: 110px !important; */
}
.mySlik .slick-current{
    background: #990000 !important;
    color: #fff !important;
}
.mySlik .slick-current span{
    color: #fff !important;
}
.mySlik .slick-current h3{
    color: #fff !important;
}
.mySlik .slick-list{
    height: 62px;
}
.mySlik .slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    z-index: 1;
    margin-top: -8px;
}
.mySlik .slick-prev{
    left: -8px;
}
.mySlik .slick-next {
    right: -3px;
}
.mySlik .slick-prev:before, .slick-next:before {
    color: #53a318;
}
.mySlik .slick-initialized .slick-slide span{
    font-size: 11px;
    color: #8FA2B7;
    margin-bottom: 2px;
    float: left;
    width: 100%;
    width: 100%;
}
.mySlik .slick-initialized .slick-slide h3{
    font-size: 13px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 2px;
}
.mySlik .slick-track{
    display: flex;
    gap: 10px;
}
.mySlik .slick-prev:before, .slick-next:before{
    font-size: 25px;
}

.reportAdminMain .employyeListingMain{
    height: auto;
}
.repViw{
    width: 100%;
    float: left;
    border: 1px solid #990000;
    padding: 12px 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 11px;
    flex-wrap: wrap;
    justify-content: space-between;
}
.repViw p{
    display: flex;
    align-items: center;
    gap: 5px;
    /* width: 47%; */
    margin: 0;
}
.repViw em{
    width: 9px;
    height: 9px;
    border-radius: 100%;
    float: left;
}
.repViwColorGreen{
    background: #0FB000;
}
.repViwColorRed{
    background: red;
}
.repViwColorYellow{
    background: #ffbe00;
}
.repViw span{
    font-weight: 400;
    font-size: 12px;
    color: #4D4D4D;
}
.progBarMain{
    width: 100%;
    float: left;
    background: #F5F5F5;
    padding: 15px;
    border-radius: 8px;
    padding-top: 4px;
}
.progBarMain h1{
    font-weight: 400;
    font-size: 15px;
    color: #333333;
}
.progBarInr{
    width: 100%;
    float: left;
}



:root {
    --color-white: #fff;
    --color-black: #333;
    --color-gray: #75787b;
    --color-gray-light: #bbb;
    --color-gray-disabled: #e8e8e8;
    --color-green: #53a318;
    --color-green-dark: #383;
    --font-size-small: .75rem;
    --font-size-default: .875rem;
  }
  
 
  
  .progBarInr .progress-bar {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0 0 4px 0;
  }
  .progBarInr .progress-bar li {
    flex: 2;
    position: relative;
    padding: 0 0 7px 0;
    font-size: var(--font-size-default);
    line-height: 1.5;
    color: var(--color-green);
    font-weight: 600;
    white-space: nowrap;
    overflow: visible;
    min-width: 0;
    text-align: center;
    border-bottom: 2px solid var(--color-gray-disabled);
  }
  .progBarInr .progress-bar li:first-child,
  .progress-bar li:last-child {
    flex: 1;
  }
  .progBarInr .progress-bar li:last-child {
    text-align: right;
  }
  .progBarInr .progress-bar li:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background-color: var(--color-gray-disabled);
    border-radius: 50%;
    border: 2px solid var(--color-white);
    position: absolute;
    left: calc(50% - 6px);
    bottom: -7px;
    z-index: 3;
    transition: all .2s ease-in-out;
  }
  .progBarInr .progress-bar li:first-child:before {
    left: 0;
  }
  .progBarInr .progress-bar li:last-child:before {
    right: 0;
    left: auto;
  }
  .progBarInr .progress-bar span {
    transition: opacity .3s ease-in-out;
    font-size: 9px;
    font-weight: 900;
  }
  .progBarInr .progress-bar li:not(.is-active) span {
    opacity: 0;
  }
  .progBarInr .progress-bar .is-complete:not(:first-child):after,
  .progress-bar .is-active:not(:first-child):after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    bottom: -2px;
    left: -50%;
    z-index: 2;
    border-bottom: 2px solid var(--color-green);
  }
  .progBarInr .progress-bar li:last-child span {
    width: 200%;
    display: inline-block;
    position: absolute;
    left: -100%;
  }
  
  .progBarInr .progress-bar .is-complete:last-child:after,
  .progress-bar .is-active:last-child:after {
    width: 200%;
    left: -100%;
  }
  
  .progBarInr .progress-bar .is-complete:before {
    background-color: var(--color-green);
  }
  
  .progBarInr .progress-bar .is-active:before,
  .progress-bar li:hover:before,
  .progress-bar .is-hovered:before {
    background-color: #007c00;
    border-color: #007c00;
    width: 10px;
    height: 10px;
    bottom: -8px;
    left: 7px;
  }
  .progBarInr .progress-bar li:hover:before,
  .progress-bar .is-hovered:before {
    transform: scale(1.33);
  }
  
  .progBarInr .progress-bar li:hover span,
  .progress-bar li.is-hovered span {
    opacity: 1;
  }
  
  .progBarInr .progress-bar:hover li:not(:hover) span {
    opacity: 0;
  }
  
  .progBarInr .x-ray .progress-bar,
  .progBarInr .x-ray .progress-bar li {
    border: 1px dashed red;
  }
  
  .progBarInr .progress-bar .has-changes {
    opacity: 1 !important;
  }
  .progBarInr .progress-bar .has-changes:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    left: calc(50% - 4px);
    bottom: -20px;
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%208%208%22%3E%3Cpath%20fill%3D%22%23ed1c24%22%20d%3D%22M4%200l4%208H0z%22%2F%3E%3C%2Fsvg%3E');
  }

  .byWicTx{
    font-weight: 400;
    font-size: 10px;
    color: #0FB000;
    font-style: normal;
  }
.verifiedIconGreen{
    color: #0FB000 !important;
    font-size: 17px !important;
}
.verifiedIconRed{
    color: red !important;
    font-size: 17px !important;
}
.myAppModalMain{

}
.myAppModalMain .MuiDialogContent-root{
    padding: 20px 10px;
}
.myAppModalMain .MuiDialog-paper{
    margin: 10px;
    width: 100%;
}
.modalHeading{
    font-weight: 400;
    font-size: 17px;
    color: #4D4D4D;
}
.forBeforAfterMod .modalHeading{
    text-align: center;
    margin-top: 27px;
}
.modalCloseIco{
    position: absolute !important;
    top: 10px;
    right: 10px;
}
.modalBodyCont{
    width: 100%;
}
.beforAftMain{
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
}
.afterSt, .beforSt{
    padding: 0px 0 2px;
    margin-bottom: 25px;
}
.beforSt{
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.afterSt{
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.afterSt::before{
    content: "";
    position: absolute;
    height: 100%;
    border-left: 1px dashed #ccc;
    left: -16px;
}
.befAftHeading{
    width: 100%;
    float: left;
    font-weight: 700;
    font-size: 12px;
    color: #4D4D4D;
    text-align: center;
    margin-bottom: 2px;
}
.befAftImgDiv{
    width: 100%;
    float: left;
    height: 85px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
}
.befAftImgDiv img{
    width: 100%;
    height: 100px;
    object-fit: cover;
}
.underContrction{
    width: 100%;
    float: left;
}
.underContrction img{
    width: 100%;
}
.deleteEmpTx{
    width: 100%;
    float: left;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    color: #FA2E2E;
}
.yellowBulet{
    background: #FBC000 !important;
}
.redBulet{
    background: #FA2E2E !important;
}
.repViewCount{
    color: #990000;
    font-weight: 900;
    font-size: 11px;
}
.adminBottomNavMain .MuiBottomNavigationAction-root{
    min-width: auto;
    padding: 0;
}
.dateCustom{
    width: 100%;
    float: left;
    margin-top: -25px;
    position: relative;
}
.dateCustom img{
    position: absolute;
    top: 19px;
    left: 15px;
}
.dateCustom input{
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 0 15px 0 52px;
    outline: none;
    font-size: 16px;
    background: transparent !important;
}
.dateCustom span{
    position: absolute;
    left: 49px;
    font-size: 10px;
    color: #8FA2B7;
    top: -4px;
    background: #fff;
    padding: 0 5px;
    letter-spacing: 1px;
}
.weeklyTaskMainOter{
    width: 100%;
    float: left;
    padding-bottom: 60px;
}
.weeklyTaskMain{
    width: 100%;
    padding: 10px;
    background: #f6f7ff;
    float: left;
}
.weeklyTaskHead{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    gap: 8px;
}
.weeklyTaskPic{
    width: 22px;
    height: 22px;
    border-radius: 100%;
    overflow: hidden;
}
.weeklyTaskPic img{
    width: 100%;
    height: 22px;
    object-fit: cover;
}
.weeklyTaskHead h1{
    font-weight: 600;
    font-size: 12px;
    color: #333333;
    display: flex;
    align-items: center;
    gap: 6px;
}
.weeklyTaskHead span{
    font-weight: 500;
    font-size: 10px;
    color: #8FA2B7;
}
.weeklyTaskTbl{
    width: 100%;
    float: left;
    margin-top: 10px;
    text-align: center;
}
.weeklyTaskTbl table thead tr th{
    padding: 5px;
    text-align: center !important;
}
.weeklyTaskTbl table thead tr:first-child{
    background: #f4f4f4;
}
.weeklyTaskTbl table thead tr:last-child {
    background: #f8f8f8;
}
.weeklyTaskTbl table thead tr:first-child th{
    font-size: 13px;
    text-align: center !important;
}
.weeklyTaskTbl table thead tr:last-child th{
    font-size: 13px;
    color: #990000;
}
.weeklyTaskTbl table tbody tr td{
    padding: 0px;
    background: #fcf4f4;
}
.weeklyTaskTbl table tbody tr td .Mui-checked{
    color: #0f9e0f;
}
.tskHeading{
    width: 100%;
    float: left;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    letter-spacing: 1px;
}
.tskDisc{
    width: 100%;
    float: left;
    font-weight: 500;
    font-size: 16px;
    color: #3e3e3e;
    line-height: 24px;
    letter-spacing: .4px;
    margin-inline: 20px;
}
.tskBuletPoints{
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    gap: 13px;
    margin-bottom: 14px;
}
.tskBuletPointsInr{
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-start;
    gap: 12px;
}
.tskBuletPointsInr em{
    min-width: 6px;
    height: 6px;
    border-radius: 100%;
    background: #990000;
    display: block;
    margin-top: 6px;
}
.tskBuletPointsInr span{
    font-weight: 500;
    font-size: 15px;
    color: #535252;
    line-height: 22px;
}

.myOtpMain{
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.myOtpMain input{
    text-align: center;
    width: 100% !important;
    height: 50px !important;
    font-size: 30px !important;
    font-weight: 800 !important;
    color: #990000 !important;
    outline: none;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
    
}
.loginDay{
    font-weight: 400 !important;
    font-size: 22px !important;
    color: #333333 !important;
    text-align: left !important;
    width: 100%;
    float: left;
    margin-bottom: 10px;
}
.loginDatTime{
    width: 100%;
    float: left;
    font-weight: 700;
    font-size: 22px;
    color: #333333;
    text-align: left;
}



/* Swipe Left Right .css */
.swipe-container {
    position: relative !important;
    /* touch-action: none !important; */
    transition: .5s;
  }
.swiped-left {
    background-color: #ffc4c4  !important;
    border-radius: 8px;
    padding-right: 115px;
    position: relative;
}
.swiped-right {
    background-color: lightgreen !important;
    border-radius: 8px;
    padding-left: 95px;
    position: relative;
}

.deleEdiBtLeft{
    position: absolute;
    left: 5px;
    top: 50%;
    margin-top: -20px;
}
.deleEdiBtRight{
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -20px; 
}
.swiped-left .emploTaskListInr{
    white-space: nowrap;
    overflow: hidden;
    /* filter: blur(1px); */
}
.swiped-right .emploTaskListInr{
    white-space: nowrap;
    overflow: hidden;
    /* filter: blur(1px); */
}
.deleEdiBtLeft svg{
    color: #333;
}
.deleEdiBtRight svg{
    color: #333;
}


.swiped-left .employyeListingInr{
    white-space: nowrap;
    overflow: hidden;
    /* filter: blur(1px); */
}
.swiped-right .employyeListingInr{
    white-space: nowrap;
    overflow: hidden;
    /* filter: blur(1px); */
}
.selectShiptMain{
    width: 100%;
    float: left;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.selectShiptInr{
    width: 100%;
    float: left;
    background: #F5F5F5;
    border-radius: 5px;
    font-weight: 400;
    font-size: 15px;
    color: #4D4D4D;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.selectShiptInr svg{
    font-size: 16px;
    display: none;
}
.forSelectShiptClass{
    
}
.forSelectShiptClass svg{
    display: block !important;
}
.forSelectShiptClass{
    background: #0FB000 !important;
    color: #fff !important;
}
.okBtn{
    width: 100%;
    background: #990000 !important;
    color: #fff !important;
    text-transform: capitalize !important;
    border-radius: 7px !important;
}
 .weeklyTaskTbl .MuiCheckbox-root{
    padding: 7px;
  }
  .weeklyTaskTbl .MuiSvgIcon-fontSizeMedium {
    font-size: 22px;
  }

  .employeeTaskListMain{

  }
  .employeeTaskListMain .adminLoginBody{
    padding: 25px 15px;
    display: block;
    height: auto;
    position: relative;
    padding-top: 5px;
  }
  .employeeTaskListMain .dateCustom{
    margin: 0;
  }
.employeeTaskListMain .progBarMain h1{
    text-align: left;
}
.employeeTaskListMain .employyeListingMain{
    margin-top: 0;
}

.forClass1{
   color: #ff0000 !important;
   width: 27px;
}

.forClass2{
    display: none !important;
    color: #007c00 !important;
}
.forClass3{
    display: none !important;
    color: #f4c904 !important;
}

.class1 {}
.class2 {}
.class3 {}

.class1 .forClass1{
    display: none !important;
}
.class1 .forClass2{
    display: block !important;
}
.class2 .forClass2{
    display: none !important;
}
.class2 .forClass1{
    display: none !important;
}
.class2 .forClass3{
    display: block !important;
}

.befAftUploadBt{
    background: #535353 !important;
    font-size: 9px !important;
    color: #fff !important;
    border-radius: 50px !important;
    text-transform: capitalize !important;
    padding: 0px 10px !important;
    overflow: hidden;
    height: 22px;
}
.befAftUploadBt input{
    position: absolute;
    opacity: 0;
    top: 0;
    height: 100%;
    width: 100%;
}
.emploProfileDiv{
    width: 100%;
    float: left;
    text-align: left;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    /* background: #7c0202; */
    padding: 9px;
    border-radius: 14px;
}
.emploProfilePic{
    min-width: 45px;
    max-width: 45px;
    height: 45px;
    border-radius: 100%;
    overflow: hidden;
    float: left;
}
.emploProfilePic img{
    width: 100%;
    height: 60px;
    object-fit: cover;
}
.emploProfileTx{

}
.emploProfileTx h1{
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    font-family: "Archivo", sans-serif;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0;
}
.emploProfileTx p{
    font-weight: 600;
    font-size: 14px;
    color: #868686;
    padding: 0;
}
.picUplod{
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.picUplod svg{
    color: #000;
    font-size: 16px;
}
.picUplod input{
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.dayTimeDateSt{
    width: 100%;
    float: left;
    text-align: left;
    margin-top: 13px;
}
.dayTimeDateSt span{
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    margin-right: 6px;
}
.dayTimeDateSt b{
    font-size: 20px;
    font-weight: 700;
    color: #fff;
}
.employeeTaskListMain .adminLoginTop{
    margin: 20px 0;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;
    margin-bottom: 10px;
}

.scrolled{
    filter: blur(5px);
}

.dateCustom select{
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 0 15px 0 52px;
    outline: none;
    font-size: 16px;
    background: transparent !important;
}
.addTaskNCateTabMain {
    padding-top: 110px;
}
.addTaskNCateTabMain .MuiTabs-flexContainer{
    display: flex;
    /* justify-content: center; */
    /* gap: 40px; */
}
.selectFreq{
    width: 100%;
    float: left;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
}
.freqMain{
    width: 100%;
    float: left;
    margin-top: 10px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
}
.freqMain button{
    background: #F1F1F1 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    padding: 8px 15px;
    color: #333333 !important;
    border-radius: 10px !important;
    width: 48.4%;
}
.freqMain button svg{
    font-size: 15px;
    display: none;
}
.freqMain .activeFreq{
    background: #7B7B7B !important;
    color: #fff !important;
}
.freqMain .activeFreq svg{
    display: block !important;
}
.expandDiv{
    width: 100%;
    float: left;
    padding: 7px;
    border: 1px solid #0fb000;
    border-radius: 8px;
    opacity: 0;
    visibility: hidden;
    display: none;
    margin-top: -4px;
    background: #f4ffeb;
}
.expanded {
    opacity: 1 !important;
    visibility: visible !important;
    display: block !important;
}
.omcCheck .MuiCheckbox-root{
    display: none;
}
.selectedOmc .omcCheck .MuiCheckbox-root{
    display: block;
}
.omcCheck .MuiIconButton-root{
    font-style: normal;
    color: #990000;
    font-size: 17px;
    font-weight: 800;
}
.selectedOmc .omcCheck .MuiIconButton-root{
    display: none;
}
.hideInpt{
    position: absolute;
    opacity: 0;
    width: 100%;
    left: 0;
    height: 100%;
    top: 0;
}
.omcTx{
    padding: 4px 7px;
    color: #ffffff !important;
    border-radius: 5px;
    background: linear-gradient(to bottom, #990000 0%, #fa6767 100%);
}
.menuMainTs .MuiPaper-rounded{
    width: 100%;
    border-radius: 16px;
    box-shadow: 0px 18px 10px -10px rgba(0, 0, 0, 0.1);
    border: 1px solid #990000;
    background: linear-gradient(to bottom, #b11616 0%, #5e0e0e 100%);
}
.menuMainTs .MuiMenuItem-root{
    border-bottom: 1px dashed #ccc;
    color: #ffff;
}
.menuMainTs .MuiMenuItem-root:last-child{
    border: none;
}
.addedSearch{
    
}

/* select {
    background: url("../images/selectIcon.svg") no-repeat !important;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance:none !important;
    -webkit-appearance: none !important; 
    appearance: none !important;
    padding-right: 2rem !important;
} */


.loaderNew{
    width: 100%;
    float: left;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 99;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loaderNew .loader {
    width: 65px;
    aspect-ratio: 1;
    position: relative;
  }
  .loaderNew .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    border-radius: 50px;
    box-shadow: 0 0 0 3px inset #990000;
    animation: l5 2.5s infinite;
  }
  .loaderNew .loader:after {
    animation-delay: -1.25s;
    border-radius: 0;
  }
  
  @keyframes l5{
    0%    {inset:0    35px 35px 0   }
    12.5% {inset:0    35px 0    0   }
    25%   {inset:35px 35px 0    0   }
    37.5% {inset:35px 0    0    0   }
    50%   {inset:35px 0    0    35px}
    62.5% {inset:0    0    0    35px}
    75%   {inset:0    0    35px 35px}
    87.5% {inset:0    0    35px 0   }
    100%  {inset:0    35px 35px 0   }
  }

.txTrans{
    display: flex;
    align-items: center;
    gap: 1px;
    text-transform: capitalize;
    color: #990000;
}
.txTrans div{
    font-size: 7px;
    text-align: left;
    margin-left: 2px;
}

.udateSheBt{
    background: #060b26;
    color: #fff;
    height: 40px;
    float: right;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}
.newSheBt{
    height: 30px;
    font-size: 12px;
    margin-top: 6px;
    width: 100%;
    background: #990000;
}
.activDeactSt{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.actSt{
    color: green !important;
    font-size: 14px !important;
}
.deActSt{
    color: #990000 !important;
    font-size: 14px !important;
}
.categoryChecks{

}
.categoryChecks .MuiFormControlLabel-root{

}
.categoryChecks .MuiButtonBase-root{
    padding: 0 9px;
}
.categoryChecks .MuiFormGroup-root{
    gap: 6px;
}


/* 21 09 2024 */
.companyDiv {
    width: 47%;
    height: 165px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    float: left;
    position: relative;
    padding-top: 50px;
}
.addCompanyDiv {
    border: 2px dashed #6ba74c;
    /* background: #ace68e; */
    cursor: pointer;
    transition: .3s;
}
.companyAddIco {
    float: left;
    display: flex;
}
.companyAddIco svg {
    font-size: 40px;
}
.companyDiv h1 {
    font-size: 13px;
    font-weight: 600;
    color: #333333;
    text-align: center;
}
.compLogImg {
    margin-bottom: 13px;
    display: flex;
    align-items: center;
}
.compLogImg img {
    width: 115px;
    height: 50px;
    object-fit: contain;
}
.comEdiIco {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.superAddminDashMain{
    width: 100%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin: 10px 0 20px;
    justify-content: center;
}

.orgnigDiv{
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    gap: 15px;
}
.orgniInner{
    width: 100%;
    height: 85px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 5px;
    float: left;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #BDC3C7;
    justify-content: center;
    font-size: 10px;
    font-weight: 700;
    cursor: pointer;
    gap: 7px;
    text-align: center;
    line-height: 14px !important;
}
.activeOrgniInner{
    background: #ebebeb;
    border: 1px solid #2e352a;
    color: #000;
}
.uploadImBox{
    width: 100%;
    background: #F9F9F9;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid #BDC3C7;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.uploadImBox h1{
    font-size: 15px;
    color: #001F3F;
    font-weight: 500;
}
.uploadImBt{
    background: #c82d33 !important;
    border-radius: 8px !important;
    color: #fff !important;
    font-size: 14px !important;
    height: 52px;
    min-width: 180px !important;
    text-transform: capitalize !important;
    letter-spacing: 1px !important;
    margin: 12px 0 !important;
}
.uploadImBt svg{
    color: #fff;
}
.logoPrevOuter{
    width: 100%;
    float: left;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 10px;
}
.logoPrev{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    display: inline-block;
    background: #fff;
    overflow: hidden;
    border: 1px solid #ccc;
}
.logoPrev img{
    width: 100%;
    height: 100px;
    object-fit: contain;
}
.visitorFilterIco{
    margin-left: 10px;
    cursor: pointer;
}

.superAdminBottomTb{

}
.stickyBtBottom{
    position: sticky !important;
    bottom: 67px;
}
.pdm .MuiIconButton-root:first-child{
    padding: 0;
}
.sortingIcoOut .MuiToggleButton-standard svg {
    margin-left: 0;
    font-size: 30px;
    color: #8f8f8f;
    transition: .5s;
}
.sortingIcoOut button{
    padding: 3px;
}
.sortingIcoOut .Mui-selected svg{
    color: #990000  !important;
}
.dtOuter .react-datepicker-wrapper{
    width: 100%;
}
.addEdiProfDivOut{
    width: 100%;
    float: left;
    text-align: center;
}
.addEdiProfDivInnrIconAtt{
    width: 165px;
    position: relative;
    display: inline-block;
}
.addEdiProfDivInnr{
    width: 165px;
    height: 165px;
    border-radius: 100%;
    overflow: hidden;
    display: inline-block;
    background: #eee;
}
.addEdiProfDivInnr img{
    width: 100%;
    height: 165px;
}
.profEdiICoDiv{
    position: absolute !important;
    bottom: 13px;
    right: 8px;
    width: 35px;
    height: 35px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    border: 1px solid #eee !important;
    min-width: auto !important;
    background: #fff !important;
}



/* added by vaishali dor top slider date  */

.dateSliderInner{
    display: flex;
    /* width: 115px; */
    float: left;
    flex-direction: column;
    align-items: center;
}

.dateSliderOuter{
    min-width: 120px !important;
}
.dataNoF{
    width: 100%;
    float: left;
    position: relative;
    text-align: center;
}
.dataNoF img{
    width: 100%;
    max-width: 400px;
    display: inline-flex;
}
.dataNoF p{
    position: absolute;
    bottom: 90px;
    text-align: center;
    width: 100%;
    left: 0;
    font-size: 20px;
    color: #898989;
}

.dataNoFNew{
    margin: 50px 0;
}
.dataNoFNew img{

}
.dataNoFNew p{
    position: static;
    margin-top: 20px; 
    font-size: 17px; 
}

.microphoneIcon{
    position: relative;
}
.microphoneIcon input{
    padding-right: 50px;
}
.microphone{
    position: absolute;
    right: 15px;
    bottom: 15px;
}


/* For FLoting Action Button */
.myFlotingBtMain{

}
.myFlotingBtMain .fab-wrapper {
    position: fixed;
    bottom: 76px;
    right: 20px;
    z-index: 9;
}
.myFlotingBtMain  .fab-checkbox {
    display: none;
  }
  .myFlotingBtMain .fab {
    position: absolute;
    bottom: -13px;
    right: -13px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #990000;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    z-index: 1;
  }
  
  .myFlotingBtMain  .fab:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .myFlotingBtMain .fab-checkbox:checked ~ .fab:before {
    width: 90%;
    height: 90%;
    left: 5%;
    top: 5%;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .myFlotingBtMain .fab:hover {
    /* background: #7cb850; */
    /* box-shadow: 0px 5px 20px 5px #7cb850; */
  }
  
  .myFlotingBtMain  .fab-dots {
    position: absolute;
    height: 6px;
    width: 6px;
    background-color: white;
    border-radius: 50%;
    top: 50%;
    transform: translateX(0%) translateY(-50%) rotate(0deg);
    opacity: 1;
    animation: blink 3s ease infinite;
    transition: all 0.3s ease;
  }
  
  .myFlotingBtMain  .fab-dots-1 {
    left: 10px;
    animation-delay: 0s;
  }
  .myFlotingBtMain  .fab-dots-2 {
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation-delay: 0.4s;
  }
  .myFlotingBtMain  .fab-dots-3 {
    right: 10px;
    animation-delay: 0.8s;
  }
  
  .myFlotingBtMain .fab-checkbox:checked ~ .fab .fab-dots {
    height: 3px;
  }
  
  .myFlotingBtMain .fab .fab-dots-2 {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }
  
  .myFlotingBtMain .fab-checkbox:checked ~ .fab .fab-dots-1 {
    width: 22px;
    border-radius: 10px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
  .myFlotingBtMain .fab-checkbox:checked ~ .fab .fab-dots-3 {
    width: 22px;
    border-radius: 10px;
    right: 50%;
    transform: translateX(50%) translateY(-50%) rotate(-45deg);
  }
  
  @keyframes blink {
    50% {
      opacity: 0.25;
    }
  }
  
  .myFlotingBtMain .fab-checkbox:checked ~ .fab .fab-dots {
    animation: none;
  }
  
  .myFlotingBtMain .fab-wheel {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10rem;
    height: 10rem;
    transition: all 0.3s ease;
    transform-origin: bottom right;
    transform: scale(0);
  }
  
  .myFlotingBtMain .fab-checkbox:checked ~ .fab-wheel {
    transform: scale(1);
  }
  .myFlotingBtMain .fab-action {
    position: absolute;
    background: #151414;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: White;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    transition: all 1s ease;
    opacity: 0;
  }
  
  .myFlotingBtMain  .fab-checkbox:checked ~ .fab-wheel .fab-action {
    opacity: 1;
  }
  
  .myFlotingBtMain .fab-action:hover {
    background-color: #7ab64e;
  }
  
  .myFlotingBtMain .fab-wheel .fab-action-1 {
    right: -1rem;
    top: 0;
  }
  
  .myFlotingBtMain .fab-wheel .fab-action-2 {
    right: 3.4rem;
    top: 0.5rem;
  }
  .myFlotingBtMain .fab-wheel .fab-action-3 {
    left: 0.5rem;
    bottom: 3.4rem;
  }
  .myFlotingBtMain .fab-wheel .fab-action-4 {
    left: 0;
    bottom: -1rem;
  }
/* End */

.emploOute{
    display: flex;
    gap: 15px;
}
.drawerMainView{
    display:flex;
    height: 100%;
    background-color: #0FB000;
}
.drawerAdminView{
    display: flex;
    width: 100%;
    flex-direction: column;
    
    
    background-color: #990000;
    padding: 20px 15px;
    max-width: 265px;
    align-items: center;
}
.avtardiv{
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.avtardiv img{
    height: 120px;
    width: 120px;
    border-radius: 75px;
}
.avtardiv p{
    font-size: 18px;
    font-weight: 600;
    color: #ffffff !important;
    text-align: center;
    line-height: 27px;
}
.logoutView{
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px 0;
    background: #eee;
    align-items: center;
    border-top: 1px solid #ccc;
}
.logoutBtn{
    display: flex;
    gap: 10px;
    align-items: center;
}
.avtardiv .userTypeName{
    font-size: 14px;
    font-weight: 600;
    color: #ffffff !important;
}

.clickAllTile{

}
.clickAllTile .slick-initialized .slick-slide{
    position: relative;
}
.clickAllTile .dateSliderInner {
    display: flex;
    float: left;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
}
.inrTileTaskName{
    font-size: 10px;
    float: left;
    width: 100%;
    margin-top: 4px;
    color: #990000;
}
.underSlikDate{
    font-size: 9px !important;
}


.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    cursor: pointer;
  }
  
  .next {
    right: 20px;
  }
  
  .prev {
    left: 20px;
  }

/* ***** */
.imgClgView{
    height: 20px;
    width: 20px;
    border-radius: 13px;
    background-color: #FFFFFF;
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.imgClgView svg{
    color: #000000;
    font-size: 16px;
}
.checkTaskAvailMain .weeklyTaskTbl table thead tr:first-child th {
    font-size: 9px;
    font-weight: 900;
}


.blink_me {
    animation: blinker 2s linear infinite;
    background: #dadada !important;
  }
  @keyframes blinker {
    10% {
      opacity: .5;
    }
  }

  .shiftTiming{
    font-size: 10px;
    margin-top: 1px;
    color: #990000;
  }


  .tabsMainD .MuiTabScrollButton-root{
    width: 22px;
    display: flex !important;
    align-items: center;
}

  .clickAllTile .slick-prev, .slick-next{
    display: none !important;
  }
  .clickAllTile .slider-container{
    position: relative;
  }
  .slikArLeft{
    position: absolute;
    z-index: 9;
    left: -10px;
    top: 12px;
    color: #616161;
  }
  .slikArRight{
    position: absolute;
    z-index: 9;
    right: -10px;
    top: 12px;
    color: #616161;
  }
  .leftBorderAdmin{
    border-left: 5px solid #990000 !important;
    background-color: #ecffea !important;
  }
  .comnCompaniesLogoMain{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .forAppDrawer{
    width: auto !important;
  }
  .alCompLogs{
    width: 70px;
    height: 45px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 45px;
    z-index: 9;
    background: #000000;
    border-radius: 10px;
  }
  .alCompLogs img{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .emploTsCompLogMain{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    float: left;
    justify-content: space-between;
  }
  .emploTsCompLogMain .dayTimeDateSt{
    display: flex;
    flex-direction: column;
    gap: 9px;
    margin: 0;
  }
  .emploTsCompLogMain .dayTimeDateSt span{
    font-size: 16px;
  }
  .emploTsCompLogMain .dayTimeDateSt b{
    font-size: 18px;
  }
  .emploTsCompLogMain .alCompLogs{
    width: 80px;
    height: 57px;
    background: #ffffff;
    padding: 7px;
    border-radius: 6px;
    position: static;
  }
  .emploTsCompLogMain .alCompLogs img{
    height: auto;
  }

  .keyboardStyle{
    position: absolute;
    bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .keyboardStyle .adminLoginBody{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .emploPrifileTbs{
    position: static !important;
    padding: 0;
  }

  .adminTaskFloatingMain {

  }
.adminTaskFloatingMain .fab-wrapper{
    position: relative;
    top: 6px;
    right: 12px;
}
.adminTaskFloatingMain .fab{
    width: 40px;
    height: 40px;
}
.adminTaskFloatingMain .fab-wheel{
    bottom: 0;
    right: 0;
    width: 60px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.adminTaskFloatingMain .fab-action{
    /* position: static; */
}
.adminTaskFloatingMain .fab-wheel .fab-action-1 {
    right: -17px !important;
    top: 26px !important;
}
.adminTaskFloatingMain .fab-wheel .fab-action-2 {
    right: -17px !important;
    top: 85px !important;
}
.adminTaskFloatingMain .fab-wheel .fab-action-3 {
    left: 30px !important;
    bottom: -192px !important;
}
.adminTaskFloatingMain  .fab-wheel .fab-action-4 {
    left: 30px !important;
    bottom: -254px !important;
}
.floatinTx{
    position: absolute;
    right: 100%;
    margin-right: -8px;
    background: #ccc;
    padding: 7px 20px;
    background: #151414;
    border-radius: 25px 0 0 25px;
    white-space: nowrap;
    text-align: right;
    padding-right: 10px;
    font-size: 12px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.schedulAcordianMain{
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    gap: 13px;
}
.schedulAcordianInr{
    width: 100%;
    float: left;
    border: 1px solid #ccc;
    border-radius: 12px;
    overflow: hidden;
}
.schedulAcordianTp{
    width: 100%;
    float: left;
    background: #FAFAFA;
    padding: 7px 15px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
}
.schedulAcordianTp b{
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    display: flex;
    align-items: center;
    gap: 5px;
}
.schedulAcordianTp b img{
    width: 20px;
}

.schedulAcordianTpRight{
    display: flex;
    align-items: center;
}
.schedulAcordianTpRight img{
    width: 29px;
    cursor: pointer;
}
.schedulAcordianTpRight .Mui-checked{
    color: #0fb000 !important;
}
.schedulAcordianTpRight .MuiSwitch-track{
    background-color: #0fb000 !important;
}
.schedulAcordianCont{
    width: 100%;
    float: left;
}
.schedulAcordianCont .tabsMainD{
    position: static;
    padding: 0;
}
.schedulAcordianCont .tabsMainD .MuiTab-textColorPrimary{
    min-width: auto;
    padding: 0 7px;
}
.shiftDaysMain{
    width: 100%;
    float: left;
}
.shiftDaysInr{
    width: 100%;
    float: left;
}
.shiftDaysInr b{
    font-weight: 700;
    font-size: 18px;
    color: #000000;
}
.shiftDaysInr b em{
    font-weight: 400;
    font-size: 11px;
    color: #333;
}
.shiftDaysInr span{
    font-weight: 700;
    font-size: 14px;
    color: #333333;
}
.shiftDaysInr .weeklyTaskTbl{
    margin-top: 0;
}
.shiftDaysInr .weeklyTaskTbl table tbody tr td{
    text-align: left;
    background: #fff;
    padding: 10px;
    font-weight: 800;
    color: #333;
}
.hrsOperation{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 13px;
    color: #333333;
}
.hrsOperation b{
    font-weight: 800;
    font-size: 12px;
    color: #333333;
}
.withPlsBt{
    width: 100%;
    float: left;
}
.withPlsBtInr{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    gap: 12px;
}
.plsB{
    color: #990000 !important;
    height: 54px;
    border-radius: 12px !important;
    border: 1px solid #990000 !important;
}
.plsB svg{
    font-size: 32px;
}
.createScheduSlik .slick-prev{
    display: block !important;
}

.schedularAccordianMain{
    width: 100%;
    float: left;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.schedularAccordianMain .MuiPaper-elevation {
    box-shadow: none !important;
    border: 1px solid #ccc;
    border-radius: 12px !important;
    margin-bottom: 0 !important;
    overflow: hidden;
    margin-top: 0 !important;
}
.schedularAccordianMain .MuiAccordion-gutters::before{
    background: none;
}
.schedularAccordianMain .MuiTypography-root{
    width: 100%;
}
.schedularAccordianMain .MuiTypography-root{
    font-weight: 600;
    font-size: 15px;
    color: #333333;
}
.schedularAccordianMain .MuiTypography-root span{
    font-weight: 400;
    font-size: 15px;
    color: #868686;
}
.schedularAccordianMain .MuiAccordionSummary-root{
    background: #FAFAFA;
}
.schedularAccordianMain .MuiAccordionDetails-root{
    float: left;
    width: 100%;
    padding: 15px;
}
.scheduCon{
    width: 100%;
    float: left;
}
.schedLab{
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    margin-bottom: 10px;
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.timingAddDiv{
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
}
.timingAddLeft{

}
.timingAddLeft label{
    width: 100%;
    float: left;
    font-weight: 500;
    font-size: 12px;
    color: #8d8d8d;
    margin-bottom: 5px;
}

.countD{
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    gap: 4px;
    align-items: center;
    font-size: 21px;
    font-weight: 800;
}
.countD button{
    width: 39px !important;
    height: 39px;
    width: 100%;
    border-radius: 5px;
    font-size: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #990000;
    font-weight: 500;
    line-height: normal !important;
}
.timingAddLeft .rs-picker-daterange>.rs-input-group.rs-input-group-inside .rs-input{
    padding: 13px 10px;
    font-size: 12px;
    letter-spacing: normal !important;
}
.setDayMain{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    gap: 3px;
    justify-content: space-between;
}
.dayInD{
    background: #eee;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 800;
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.activDs{
    background: #990000;
    color: #fff;
}
.activPartial{
    background: yellow;
    color: #333;
}
.rs-calendar-only-time .rs-calendar-time-dropdown-column {
    min-width: 46px;
}
.witPlOut{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    gap: 10px;
}
.addMorScheTimBt{
    width: 100% !important;
    border: 1px solid #990000 !important;
    margin-top: 15px !important;
    color: #990000 !important;
    text-transform: capitalize !important;
}
.afterAddShiftDiv{
    width: 100%;
    float: left;
}
.afterAddShiftInr{
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.afterAddShiftInr em{
    font-weight: 400;
    font-size: 12px;
    color: #868686;
}
.afterAddShiftInr p{
    font-weight: 500;
    font-size: 12px;
    color: #868686;
}
.afterAddShiftInr span{
    
}



.actionAftrSc{
    display: flex;
    align-items: center;
}
.actionAftrSc img{
    width: 15px;
}
.usrsB{
    gap: 5px;
    font-size: 17px !important;
    color: #990000 !important;
    font-weight: 800;
}

.afterAddShiftInr:last-child{
    border-bottom: 1px dashed #ccc;
    margin-bottom: 11px;
    padding-bottom: 6px;
}
.twoBtLi{
    width: 100%;
    display: flex;
    gap: 15px;
}
.saveShiftB{
    background: #7eba52 !important;
    color: #fff !important;
    border: 1px solid #7eba52 !important;
}
.shifAvailabiMain{
    width: 100%;
    float: left;
}
.ShifPreviewModCont{
    width: 100%;
    float: left;
    padding: 15px;
}
.ShifPreviewModCont h1{
    font-weight: 600;
    font-size: 13px;
    color: #0FB000;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ShifPreviewModCont h1 svg{
    
}
.contError{
    color: #FA2E2E !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ShifPreviewModCont h1 span{
    
}
.connectionsDiv {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}
.pendingReIm {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
}
.pendingReIm img {
    width: 100%;
    height: 40px;
    object-fit: cover;
}
.pendingRex h1 {
    font-weight: 600;
    font-size: 13px;
    color: #333333;
    margin-bottom: 2px;
}
.myAvailabilityMain{
    width: 100%;
    float: left;
}
.myAvailabilityHeading{
    width: 100%;
    float: left;
    font-weight: 400 !important;
    font-size: 20px !important;
    color: #333333 !important;
    text-align: left !important;
    margin-bottom: 10px !important;
}
.weeklyAvailibMain{
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 5px;
}
.weeklyAvailibMain label{
    width: 100%;
    float: left;
    font-weight: 400;
    font-size: 13px;
    color: #868686;
    margin-bottom: 5px;
}
.weeklyAvailibMain h3{
    font-weight: 700;
    font-size: 15px;
    color: #868686;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.shifTx{
    font-weight: 400;
    font-size: 15px;
    color: #868686;
}
.newScheAvailMain{

}
.newScheAvailMain .MuiFormControlLabel-root{
    margin-right: 0;
}
.newScheAvailMain .Mui-checked{
    color: #4caf50 !important;
}
.avalabilyInrTab{
    width: 100%;
    float: left;
    padding: 15px;
}
.avalabilyInrTab .afterAddShiftInr{
    border: none;
    margin: 0;
    padding: 0;
}
.avalabilyInrTab .actionAftrSc{
    font-size: 16px;
    gap: 5px;
    color: #990000;
}
.timeNH{
    display: flex;
    align-items: center;
}
.timeNH .MuiCheckbox-root {
    padding-left: 0;
}
.schedulAcordianTp .MuiTypography-root{
    font-weight: 600;
    font-size: 15px;
    color: #333333;
}

.adminBodyNewVJ {
    margin-top: 10px;
}
.datNTmNew{
    width: 100%;
    float: left;
    font-size: 13px !important;
    margin-top: 6px;
    font-weight: 400 !important;
    color: #333 !important;
}
.datNTmNew b{
    font-size: 15px !important;
    color: #990000 !important;
    font-weight: 700 !important;
}
.switchAccount{
    display: flex;
    align-items: center;
    gap: 5px;
    color: #fff; 
}
.acount1{
    width: 25px;
    height: 25px;
    overflow: hidden;
    border-radius: 100%;
}
.acount1 img{
    width: 100%;
    object-fit: contain;
    height: 25px;
}







.profile-switcher {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .profile {
    width: 100px;
    height: 100px;
    margin: 0 10px;
    border: 2px solid transparent;
    border-radius: 50%;
    overflow: hidden;
    transition: border-color 0.3s, transform 0.3s; /* Add transform transition */
  }
  
  .profile.selected {
    border-color: blue; /* Highlight selected profile */
    transform: scale(1.1); /* Slightly enlarge selected profile */
  }
  
  .profile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    transition: opacity 0.3s; /* Add transition for opacity */
  }
  
  .switch-icon {
    cursor: pointer;
    font-size: 24px;
    padding: 0 10px;
  }
  
  /* Optional: Add a transition for the entire switcher */
  .profile-switcher {
    transition: transform 0.3s;
  }
  .switchAccount .selected{
    border: 3px solid #fff;
  }


  .progessTop{padding-top: 10px;}








  .speech-to-text {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .mic-icon {
    font-size: 48px;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .mic-icon:hover {
    transform: scale(1.1);
  }
  
  .mincVoiceDiv {
    width: 100%;
    position: fixed;
    left: 0;
    height: 100%;
    z-index: 99;
    top: 0;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    transition: .3s;
    overflow-x: auto;
  }
  
  .transcript-input {
    width: 100%;
    font-size: 18px;
    margin-bottom: 40px;
    padding: 0 15px;
    border: none;
    border-radius: 50px;
    transition: opacity 0.5s ease;
    text-align: center;
  }
  .mincVoiceBt{
    background: #e5e5e5 !important;
    color: #000000 !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
    height: 80px;
    width: 80px;
    padding: 0 34px !important;
    border-radius: 50px !important;
    margin-top: 20px !important;
  }
  .mincVoiceBt svg{
    font-size: 40px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


  



  .pulsingBt {
    min-width: 100px !important;
    min-height: 100px;
    padding: 10px !important;
    background-color: #990000 !important;
    cursor: pointer !important;
    outline: 0 !important;
    border: none !important;
    border-radius: 100% !important;
    transition: all .3s ease !important;
    animation: pulse 1.6s infinite;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pulsingBt svg{
    color: #ffffff;
    font-size: 59px;
  }
  /* Animations */
  
  @-webkit-keyframes pulse {
    
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(215, 17, 73, .85);
      box-shadow: 0 0 0 0 rgba(215, 17, 73, .85);
    }
    
    70% {
      -webkit-box-shadow: 0 0 0 2em rgba(215, 17, 73, 0);
      box-shadow: 0 0 0 2em rgba(215, 17, 73, 0);
    }
    
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(215, 17, 73, 0);
      box-shadow: 0 0 0 0 rgba(215, 17, 73, 0);
    }
    
  }
  
  @keyframes pulse {
    
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(215, 17, 73, .85);
      box-shadow: 0 0 0 0 rgba(215, 17, 73, .85);
    }
    
    70% {
      -webkit-box-shadow: 0 0 0 2em rgba(215, 17, 73, 0);
      box-shadow: 0 0 0 2em rgba(215, 17, 73, 0);
    }
    
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(215, 17, 73, 0);
      box-shadow: 0 0 0 0 rgba(215, 17, 73, 0);
    }
    
  }


.rs-picker-popup{
    z-index: 9999 !important;
  }
  .schecdulEditMain .rs-picker-toggle-wrapper {
    width: 100%;
}
.schecdulEditMain .rs-picker-daterange>.rs-input-group.rs-input-group-inside .rs-input {
    padding-right: 0;
    padding-top: 18px;
    padding-bottom: 18px;
}
.schecdulEditMain .countD{
    display: flex;
    justify-content: center;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid #ccc;
}
.modBt{
    background: #a92d2d !important;
    color: #fff !important;
    text-transform: capitalize !important;
    height: 50px;
    width: 100%;
    border-radius: 10px !important;
}
.tskDiscDeatils{
    margin: 0;
}
.tskDiscDeatils ol, ul{
    padding-left: 20px;
}
.tskDiscDeatils div{
    white-space: normal;
    word-wrap: break-word;
}




.profileSwitchOuter{

}
.profileSwitchOuter .adminBottomNavMain{
    bottom: 50px;
}
.profileSwitchMain{
    width: 100%;
    float: left;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px;
    z-index: 9;
    background: #fff;
}
.profileSwitchInr{
    background: #ECECEC;
    width: 100%;
    border-radius: 50px;
    padding: 3px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}
.profileSwitchMain button{
    width: 100%;
    border-radius: 50px;
    font-weight: 400;
    font-size: 13px;
    color: #333333;
    text-transform: capitalize;
    gap: 5px;
    background: #e2e2e2;
}
.profileSwitchMain button svg{
    font-size: 19px;
}
.activeProSwich{
    background: #990000 !important;
    color: #fff !important;
}
.switchAccount .MuiSwitch-sizeMedium{

}
.switchAccount .Mui-checked .MuiSwitch-thumb{
    background: #0fb000 !important;
}
.switchAccount .Mui-checked+.MuiSwitch-track{
    background: #0fb000 !important;
}
.switchAccount{
    gap: 0;
}
.switchAccount .MuiSwitch-sizeMedium {
    width: 50px;
    height: 31px;
}
.switchAccount .MuiSwitch-switchBase{
    padding: 7px;
    color: #eaeaea;
}
.switchAccount .MuiSwitch-thumb{
    width: 16px;
    height: 16px;
}
.acount1{
    border: 1px solid #333;
    display: flex;
    align-items: center;
    justify-content: center;
}
.acount1 svg{
    color: #333;
    font-size: 19px;
}
.actPro{
    width: 31px;
    height: 31px;
    background: #0fb000;
    border: 1px solid #0fb000;
}
.actPro svg{
    color: #fff;
}
.dayDateTimeTx{
    width: 100%;
    float: left;
    font-weight: 800;
    font-size: 16px;
    color: #333333;
}
.topPadinTab{
    padding: 100px 15px 57px;
}
.personalTsProfileTpOut{
    display: flex;
    gap: 10px;
    align-items: center;
}
.personalTsProfileTp{
    width: 35px;
    height: 35px;
    border-radius: 100%;
    overflow: hidden;
}
.personalTsProfileTp img{
    width: 100%;
    height: 35px;
    object-fit: cover;
}
.personalTsProfileTpOut h2{
    font-size: 13px;
    font-weight: 500px;
    color: #000000;
}
.MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3 !important;
  }

.inrTabAddBt{
    min-width: 50px !important;
    height: 42px;
    position: sticky !important;
    background: #ffffff !important;
    z-index: 9;
    left: 0;
    border: 1px solid #ccc !important;
    color: #333 !important;
    flex-direction: column;
    font-size: 9px !important;
    font-weight: 900 !important;
    border-radius: 7px !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}
.inrTabAddBt svg{
    font-size: 17px;
}
.sharedWith{
    width: 100%;
    float: left;
    margin-bottom: 10px;
}
.showReferredIm {
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.showReferredImDiv {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    overflow: hidden;
    margin-left: -12px;
    border: 2px solid #fff;
}
.showReferredImDiv img {
    width: 100%;
    height: 30px;
    object-fit: cover;
}
.showMoreClick {
    font-weight: 700;
    font-size: 13px;
    color: #333333;
    margin-left: 10px;
    cursor: pointer;
}
.showMoreClick span{
    font-weight: 600;
    font-size: 11px;
    color: #990000;
}
.sharedWiTx{
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    margin-right: 20px;
}
.voiceDiv{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    transition: .5s;
}
.voiceDiv input{
    padding-right: 40px;
    font-size: 10px;
    height: 50px;
}
.voiceDiv svg{
    position: absolute;
    right: 8px;
}
.toggleSearch{
    width: 100%;
    transition: .5s;
    display: none;
    position: relative;
}
.toggleSearch input{
    padding-right: 40px;
    height: 50px;
}
.toggleSearch svg{
    position: absolute;
    right: 10px;
    top: 12px;
    color: #787575;
}
.addedSearch .toggleSearch{
    display: block;
}
.addedSearch .voiceDiv{
    display: none; 
}
#searchId .sortingIcoOut button{
    padding: 0;
    border: none;
}

.mincVoiceDiv h1{
    font-weight: 400;
    font-size: 20px;
    color: #333333;
    margin-bottom: 45px;
}
.wawIm{
    width: 100%;
}
.speekAddedOuter{
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 33px;
    overflow-y: auto;
    max-height: 125px;
}
.speekAdded{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
}
.speekAdded p{
    width: 100%;
    float: left;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 300;
    color: #333333;
}
.mincVoiceDiv .logginBt{
    min-height: 50px;
}
.closeInSpeek{
    position: absolute;
    top: 15px;
    right: 15px;
}
.tabMickMain{
    width: 100%;
    float: left;
    background: #F9F9F9;
    padding: 10px;
    position: relative;
    padding-top: 42px;
    border: 1px solid #eee;
    margin-top: -220px;
    opacity: 0;
    visibility: hidden;
    transition: .5s;
}
.openMickDiv{
    opacity: 1;
    visibility: visible;
    margin-top: 0;
}
.tabMickClo{
    position: absolute;
    right: 8px;
    top: 8px;
}
.tabMickMainInp{
    position: relative;
}
.tabMickMainInp input{
    width: 100%;
    float: left;
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 0 15px;
    height: 50px;
    outline: none;
    color: #333;
    font-size: 15px;
    padding-right: 50px;
}
.tabMickMainInp svg {
    position: absolute;
    right: 9px;
    top: 10px;
    color: #990000;
    font-size: 28px;
}
.forProSwicherPatch{
    padding-bottom: 107px;
}
.sharedWithScreenMain{
    width: 100%;
    float: left;
}
.recentSharedView{
    width: 100%;
    float: left;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 15px;
    background: #fff;
    z-index: 9;
    transition: .3s;
}
.sharedLabl{
    font-weight: 400;
    font-size: 13px;
    color: #333333;
}
.recentSharedHoriScroll{
    width: 100%;
    float: left;
    display: flex;
    gap: 10px;
    overflow-x: auto;
    margin-top: 8px;
}
.contactListMain{
    width: 100%;
    float: left;
}
.contactListInr{
    width: 100%;
    float: left;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 19px;
}
.contactListInr .Mui-checked{
    color: #0FB000 !important;
}
.contactListInr .MuiCheckbox-root{
    padding: 0;
}
.contactListInr .speekAdded p{
    gap: 20px;
}
.addVoicPointBt{
    width: 100% !important;
    border-radius: 50px !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    color: #ffffff !important;
    text-transform: capitalize !important;
    gap: 5px !important;
    background: #990000 !important;
    margin-top: 10px !important;
}
.emploTsTop{
    margin-bottom: 12px;
    position: relative;
    z-index: 9;
}
.profileSwitchOuter .MuiBottomNavigation-root .MuiButtonBase-root{

}
.proDivTop{
    width: 100%;
    float: left;
    background: #990000;
    padding: 15px;
    position: relative;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-bottom: 37px;
}
.proDivTop h1{
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
}
.proDivTop p{
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
}
.proDivOuter{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    position: relative;
}
.ediPro{
    font-size: 20px;
    position: absolute;
    right: -7px;
    bottom: 0;
}
.proDivIn{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;  
}
.proDivIn img{
    width: 100%;
    height: 50px;
    object-fit: cover;
}
.taskCountMain{
    width: 100%;
    float: left;
    background: #ffff;
    padding: 15px;
    margin-top: -30px;
    z-index: 9;
    position: relative;
    border-radius: 13px;
    box-shadow: 0px 23px 10px -20px rgba(0, 0, 0, 0.1);
    border: 1px solid #990000;
    display: flex;
    justify-content: space-between;
}
.taskCountIn{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    border-right: 1px solid #ccc;
}
.taskCountIn:last-child{
    border: none;
}
.taskCountIn h1{
    font-weight: 900;
    font-size: 18px;
    color: #333333;
    display: flex;
    align-items: center;
    gap: 5px;
}
.taskCountIn h1 svg{
    font-size: 21px;
}
.taskCountIn h2{
    font-weight: 600;
    font-size: 12px;
    color: #868686;
}
.myProfileAcoMain{
    width: 100%;
    float: left;
    margin-top: 15px;
}
.myProfileAcoMain .MuiPaper-rounded{
    box-shadow: none;
}
.myProfileAcoMain .MuiPaper-elevation::before{
    border: none !important;
    background: transparent;
}
.myProfileAcoMain .MuiAccordionSummary-content{
    font-weight: 600;
    font-size: 13px;
    color: #333333;
    margin: 0 !important;
}
.myProfileAcoMain .MuiAccordionSummary-root{
    padding: 0;
    min-height: 40px !important;
}
.myProfileAcoMain .MuiAccordionDetails-root{
    padding: 0;
}
.proContentAcordinOut{
    width: 100%;
    float: left;
}
.proContentAcordinInr{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.proCont{
    display: flex;
    gap: 1px;
}
.proCont input{
    width: auto;
    border: none;
    font-weight: 400;
    font-size: 10px;
    color: #868686;
}
.proCont p{
    font-weight: 500;
    font-size: 10px;
    color: #333333;
    width: 100%;
    display: flex;
    flex-direction: row;
    float: left;
}
.proContShar{
    font-weight: 600;
    font-size: 13px;
    color: #28979F;
    display: flex;
    align-items: center;
    gap: 2px;
}
.proContShar svg{
    color: #28979F;
    font-size: 21px;
}
.proContDele{

}
.proContDele svg{
    color: #FA2E2E;
    font-size: 20px;
}
.proContAct{
    display: flex;
    align-items: center;
    gap: 10px;
}

.addNewB{
    height: 40px;
    margin-bottom: 9px !important;
}
.createScheduSlik .slick-prev, .slick-next{
    display: none !important;
}
.scrolledTab{
    position: fixed;
    z-index: 999;
    background: #424242;
    left: 0;
    width: 100%;
    padding: 12px 0;
    top: 55px;
    transition: .3s;
    box-shadow: 0px 19px 12px -16px rgba(0, 0, 0, 0.1);
}
.scrolledTab .MuiTab-textColorPrimary{
    color: #ccc;
}
.scrolledTab .Mui-selected{
    color: #fff !important;
}
.scrolledTab .MuiTabs-indicator{
    background: #ffffff !important;
}
.scrolledTab .inrTabAddBt{
    margin: 0 !important;
}
.scrolledOpenMic{
    top: 120px;
    z-index: 99;
    border-radius: 0 0 15px 15px;
    position: fixed;
    left: 0;
}
.scrolledDate{
    position: fixed;
    left: 0;
    z-index: 99;
    background: #dddddd;
    padding: 6px 0px;
    top: 55px;
    margin: 0 !important;
    box-shadow: 0px 19px 12px -16px rgba(0, 0, 0, 0.1);
}
.scrolledDate .slick-list{
    height: auto !important;
}
.scrolledDate .slick-initialized .slick-slide{
    background: #ffffff;
}
.scrolledDate .slikArLeft {
    left: 2px;
}
.scrolledDate .slikArRight {
    right: 2px;
}
.mySlik{
    transition: .3s;
}


.scrolledDate2{
    position: fixed;
    left: 0;
    z-index: 99;
    background: #dddddd;
    padding: 6px 0px;
    top: 115px;
    margin: 0 !important;
    box-shadow: 0px 19px 12px -16px rgba(0, 0, 0, 0.1);
    transition: .3s;
}
.scrolledDate2 .slick-list{
    height: auto !important;
}
.scrolledDate2 .slick-initialized .slick-slide{
    background: #ffffff;
}
.scrolledDate2 .slikArLeft {
    left: 2px;
}
.scrolledDate2 .slikArRight {
    right: 2px;
}

.scrolledDate22{
    position: fixed !important;
    left: 0;
    z-index: 99;
    background: #dddddd;
    padding: 6px 0px;
    top: 114px;
    margin: 0 !important;
    box-shadow: 0px 19px 12px -16px rgba(0, 0, 0, 0.1);
    transition: .3s;
}


.scrolledRecentShared{
    position: fixed;
    left: 0;
    padding: 7px 15px;
    top: 65px;
    background: #fffce0;
}
.scheddulShifBt{
    padding: 15px 9px !important;
    justify-content: flex-start !important;
    width: 100%;
}
.browNew{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 500;
    position: relative;
}
.browNew input{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: calc(100% - 185px);
    opacity: 0;
}
.myAvailibiSetDay{
    gap: 9px;
}
.myAvailibiSetDay .dayInD{
    background: #ff0000;
    color: #ffff;
}
.myAvailibiSetDay .activDs{
    background: #0fb000;
}
.myAvailibiSetDay .activDsPartial{
    background: #dfad0b;
}
.daySlider{
    width: 100%;
    float: left;
    text-align: center;
}
.daySlider .slick-prev:before, .slick-next:before{
    color: #333 !important;
}
.daySlider .slick-prev{
    display: block !important;
    z-index: 9;
    left: 20px;
    top: 15px;
}
.daySlider .slick-next{
    display: block !important; 
    right: 20px;
    top: 15px;
    margin: 0;
}
.daySlider .slick-next::before{
    font-size: 20px;
}
.daySlider .slick-prev::before{
    font-size: 20px;
}
.daySlider .slick-dots{
    top: 29px;
    bottom: auto;
}
.slidDayHeading{
    width: 100%;
    float: left;
    font-weight: 600;
    font-size: 20px;
    color: #333333;
    margin-bottom: 30px;
}
.ShifPreviewModCont{
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.tabMickMainInpOut{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    gap: 10px;
}
.tabMickMainInpOut .tabMickMainInp input{
    font-size: 10px;
}
.tabMickMainInpOut .tabMickMainInp {
    width: 100%;
}
.bagNSwitch{
    text-align: center;
    font-size: 7px;
    font-weight: 900;
    color: #333;
}
.bagNSwitchInr{

}
.repeatMain{
    width: 100%;
    float: left;
}
.repeatOt{
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-around;
    gap: 4px;
}
.repeatOt button{
    background: #fff;
    font-size: 11px;
    font-weight: 400;
    text-transform: capitalize;
    color: #868686;
    min-width: auto;
    height: 30px;
    padding: 0 10px;
    border-radius: 2px;
    border: 1px solid #ccc;
}
.repeatMain h3{
    font-weight: 500;
    font-size: 12px;
    color: #333333;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 10px;
    float: left;
    width: 100%;
    margin-bottom: 8px;
}
.repeatMain h3 svg{
    font-size: 18px;
}
.activeRepeat{
    color: #990000 !important;
    font-weight: 800 !important;
    border: 1px solid #990000 !important;
}
.increDicreMain{
    
}
.increDicreMain button{
    min-width: auto;
    padding: 0;
}
.increDicreMain .number{
    font-size: 16px;
    font-weight: 800;
    color: #333;
}
.increDicreMain .ticket-amount{
    display: flex;
    gap: 6px;
}
.increDicreMain button svg{
    color: #333;
}
.increDicreMain .option-adult{
    display: flex;
    align-items: center;
    gap: 10px;
}
.increDicreMain .option-adult p{
    font-size: 14px;
    color: #333;
    font-weight: 800;
}
.colTask{
    width: 10px;
    height: 10px;
    display: flex;
    background: green;
    border-radius: 100%;
}
.schedulAcordianTp .Mui-checked{
    color: #0fb000;
}
.dayWMain{
    width: 100%;
    float: left;
    text-align: left;
    padding: 15px;
    border-bottom: 1px dashed #eee;
}
.dayWMain h1{
    font-weight: 600;
    font-size: 13px;
    color: #990000;
    text-align: left;
    margin-bottom: 5px;
}
.dayWInr{
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dayWInr p{
    font-weight: 500;
    font-size: 14px;
    color: #868686;
}
.dayWInr p .MuiFormControlLabel-root{
    margin-right: 0 !important;
}
.dayWInr span{
    font-weight: 400;
    font-size: 14px;
    color: #868686;
}
.dayWInr b{
    display: flex;
    align-items: center;
    gap: 5px;
    color: #990000;
    font-size: 16px;
}
.dayWInr b svg{
    color: #333;
    font-size: 19px;
}
.schedulAcordianInr .Mui-checked{
    color: #0fb000 !important;
}
.availabilityTpNew{
    gap: 9px !important;
}
.availabilityTpNew label{
    margin-right: 0;
}
.availabilityTpNew span{
    font-size: 13px;
    color: #990000;
    font-weight: 500;
}
.schedulAcordianTpRiTx{
    font-size: 13px;
    font-weight: 700;
    color: #333;
}

.checkUpdatB{
    background: #0fb000;
    color: #fff !important;
    border-radius: 4px;
}
.checkUpdatB button{
    width: 100%;
    padding: 0;
    color: #fff;
    border-radius: 0;
}

.tabMickMainInpnew{
    width: 100%;
    float: left;
    margin-bottom: 10px !important;
}
.myMick img{
    position: absolute;
    top: 12px;
    width: 130px;
    left: 0;
}
.forReportNewTabs{
    /* padding-top: 135px; */
    padding-top: 190px;
}
.forReportDateSelection{
    position: relative;
    z-index: 99;
}
.uploadedImMain{
    width: 100%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.uploadedImMain .uploadedImInr{
    position: relative;
    width: 48%;
    overflow: hidden;
    height: 85px;
    border-radius: 10px;
    border: 4px solid #cdcdcd;
}
.uploadedImMain .uploadedImInr svg{
    position: absolute;
    right: 2px;
    top: 6px;
    background: #fff;
    color: #000;
    border-radius: 100%;
}
.newConInModal{
    gap: 20px;
}
.newConInModal button{
    width: 100% !important;
}
.shiftHeadinInTab{
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    text-align: center;
    margin-bottom: 15px;
}
.dayWInrNe{
    margin-bottom: 13px;
}
.dayWInrNe:last-child{
    margin-bottom: 0;
}
.dayWInrNe .txTrans{
    gap: initial;
}
.acordingTabSubTx{
    font-size: 13px;
    color: #707070;
    margin-left: 5px;
}
.tabsMainDInr .MuiTabs-flexContainer{
    justify-content: flex-start;
    gap: 20px;
}
.emplShifSlider{

}
.emplShifSlider .shiftHeadinInTab{
    margin-bottom: 30px;
}
.emplShifSlider .slick-dots {
    top: 20px;
}
.emplShifSlider .slick-dots li{
    margin: 0;
}
.emploShitContMain{
    width: 100%;
    float: left;
    border: 1px solid #ccc;
    padding: 10px;
    background: #f8f8f8;
}
.emploShitConTop{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.emploShitConTopLft{
    display: flex;
    align-items: center;
    gap: 13px;
}
.emploShitConTopLft h1{
    font-weight: 500;
    font-size: 15px;
    color: #333333;
}
.emploShitConTopPro{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
}
.emploShitConTopPro img{
    width: 100%;
    height: 40px;
    object-fit: cover;
}
.emploShitConTop p{
    font-weight: 500;
    font-size: 10px;
    color: #333333;
}
.emploShitConTop p b{
    font-weight: 800;
    font-size: 12;
    color: #0FB000;
}
.emploShitContMain .schedularAccordianMain .MuiTypography-root{
    text-align: left;
}
.newSchedAccordin{
    
}
.newSchedAccordin .MuiAccordionDetails-root{
    padding: 0;
}
.newSchedAccordin .weeklyTaskTbl{
    margin-top: 0;
}
.emploShitContMain .Mui-checked{
    color: #0f9e0f !important;
}
.tblTiming{
    font-size: 10px;
    float: left;
    color: #000;
    font-weight: 600;
    text-align: left;
    padding: 5px 4px;
    line-height: 14px !important;
    display: flex;
    align-items: center;
}
.activeAvail{
    background: #FFFB00 !important;
    border-radius: 6px;
    border: 2px solid #ffff;
}
.tblTiming svg{
    font-size: 15px !important;
}

.schedlPreviewMain{
    width: 100%;
    float: left;
}
.schedlPreviewMain .hrsOperation{
    justify-content: center;
    margin-bottom: 15px;
}
.stickHeading{
    width: 100%;
    float: left;
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
    background: #171717;
    padding: 13px 15px;
    text-align: center;
    position: sticky;
    top: 54px;
    box-shadow: 0px 16px 9px -13px rgba(0, 0, 0, 0.2);
    z-index: 2;
}
.schedulPreviewTblMain{
    width: 100%;
    float: left;
    border-bottom: 20px solid #f8f8f8;
}
.schedulPreviewTblMain table {
    text-align: left;
}
.schedulPreviewTblMain table thead tr th {
    padding: 15px 5px;
}
.schedulPreviewTblMain table thead tr th p{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    gap: 3px;
}
.schedulPreviewTblMain table thead tr{
    border-bottom: 1px solid #ccc;
    background: #c40404;
}
.schedulPreviewTblMain table tbody tr td {
    padding: 10px 5px;
    text-align: center;
}
.schedulPreviewTblMain table tbody tr{
    border-bottom: 1px solid #eee;
}
.schedulPreviewTblMain .showReferredIm{
    display: flex;
    flex-direction: column;
    margin: 0;
}
.schedulPreviewTblMain .showMoreClick{
    margin-left: 0;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.schedulPreviewTblMain .showReferredImDiv{
    margin-left: -19px;
}
.schedulPreviewTblMain .showMoreClick span{
    font-size: 9px;
    font-weight: 800;
}
.schedProMulti{
    display: flex;
    padding-left: 16px;
}
.dayNDateCol{
    font-style: italic;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.dayNDateCol h1{
    font-weight: 600;
    font-size: 11px;
    color: #000;
    line-height: 16px !important;
    white-space: normal;
}
.dayNDateCol span{
   font-weight: 600;
   font-size: 13px;
   color: #fff; 
}
.previewScheduModMain{
    width: 100%;
    float: left;
    margin-top: 15px;
}
.previewScheduModHeadinng{
    font-weight: 400;
    font-size: 24px;
    color: #333333;
    text-align: center;
    margin-bottom: 15px;
}
.previewScheduModHeadinng b{
    font-weight: 700;
    font-size: 24px;
    color: #333333;
}
.schedulStatusOuter{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 10px 15px;
}
.schedulStatusInr{
    width: 100%;
    float: left;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 6px 10px;
}
.schedulStatusInr span{
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
}
.schedulStatusInr span em{
   width: 11px;
   height: 11px;
   border-radius: 100%; 
   display: block;
}
.schedulStatusInr p{
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    text-align: center;
}
.statusgreen{
    background: #0FB000;
}
.statusOrange{
    background: #F9B22F;
}
.statusRed{
    background: #FA2E2E;
}
.shiftViewData{
    width: 100%;
    float: left;
    background: #990000;
    padding: 10px;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
}
.shiftViewData h2{
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 5px;
}
.shiftViewData p{
    font-weight: 400;
    font-size: 16px;
}
.searchNSortHeading{
    width: 100%;
    float: left;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
}
.searchNSortMain{
    width: 100%;
    float: left;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
}
.searchNSortInr{
    width: 100%;
    float: left;
    background: #ECECEC;
    border-radius: 12px;
    padding: 8px 10px;
}
.searchNSortInr .emploShitConTopPro{
    width: 30px;
    height: 30px;
}
.searchNSortInr .emploShitConTopPro img{
    height: 30px;
}

.statusCol1{
    border-left: 8px solid #0fb000;
}
.statusCol2{
    border-left: 8px solid #f9b22f;
}
.statusCol3{
    border-left: 8px solid #FA2E2E;
}
.accordianDelete{
    display: flex;
    align-items: center;
    gap: 10px;
    text-transform: capitalize;
}
.accordianDelete img{

}
.archiveIcon{
    font-size: 27px !important;
    margin: 0 10px;
    color: #990000;
}
.mentionAvailabiMain{
    width: 100%;
    float: left;
    background: #F6F6F6;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mentionAvailabiLf{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 7px;
}

.mentionAvailabiLf h1{
    font-weight: 500;
    font-size: 12px;
    color: #333333;
    display: flex;
    align-items: center;
    gap: 10px;
}
.mentionAvailabiLf h1 svg{

}
.mentionAvailabiRg{
    display: flex;
    gap: 11px;
}
.mentionAvailabiRg p{
    font-weight: 700;
    font-size: 13px;
    color: #868686;
}
.mentionAvailabiRg p b{
    font-weight: 700;
    font-size: 15px;
    color: #990000;
}
.setPrefeMain{
    margin-bottom: 5px;
}
.setPrefeMain input{
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 15px;
    font-size: 12px;
    margin-bottom: 10px;
    outline: none;
}
.setPrefeMain button{
    width: 100%;
    background: #0fb000;
    color: #fff;
    text-transform: capitalize;
    font-size: 15px;
    height: 40px;
    border-radius: 7px;
}
.setPrefeMain button:hover{
    background: #0fb000 !important;
}
.setPrefeMain .MuiAccordionSummary-root{
    min-height: 50px !important;
}
.noteTs{
    font-size: 12px;
    color: #333;
    margin-top: 11px;
    font-weight: 500;
}
.noteTs b{

}
.archiveListingMain{
    width: 100%;
    float: left;
}
.archiveListingHed{
    width: 100%;
    font-size: 18px;
    float: left;
    margin-bottom: 12px;
    font-weight: 600;
}
.archiveListingMain .schedulAcordianTp{
    padding: 10px;
    border: none;
    border-radius: 7px;
    margin-bottom: 10px;
}
.expandTx{
    float: left;
    width: 100%;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    padding: 9px;
    background: #fafafa;
}
.expandTx .schedLab{
    margin-top: -19px;
    padding-left: 20px;
}
summary {
    display: flex;
    justify-content: space-between;
  } 
  summary::after {
    content: '';
    width: 0;
    height: 0;
    border-top: 8px solid #858585;
    border-inline: 5px solid transparent;
    transition: 0.2s;
    top: 5px;
    position: relative;
  }
  .scheduCon{
    margin-top: 12px;
  }

.widthFixedSlide .slick-initialized .slick-slide{
    box-shadow: 0px 12px 9px -9px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    height: 40px;
}
.widthFixedSlide .slick-initialized .slick-slide h3{
    font-size: 12px;
}
.widthFixedSlide .slick-list{
    height: 42px;
}
.newUpImMain{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    gap: 8px;
    border: 2px dashed #eee;
    background: linear-gradient(to bottom, #ffffff 0%, #f3f3f3 50%, #ededed 51%, #ffffff 100%);
}
.newUpImMain svg{
    font-size: 40px;
    color: #bebebe;
}
.newUpImMain .befAftUploadBt{
    position: static !important;
}
.prefranceTx h1{
    font-size: 14px;
}
.prefranceTx p{
    font-size: 12px;
    color: #9d9d9d;
    font-family: arial;
    font-weight: 500;
    margin-top: 4px;
}



/* added by vaishali  */

.viewPreferenceClickOuter {
    background-color: #f7f7f7 ;
    padding: 10px 0;
    border-radius: 8px ;
}
.viewPreferenceClickOuter h6{
    /* margin: 5px ; */
    font-size: 14px;
    font-weight: 500;
    padding-left: 10px;
    color: #333333;
}
.viewPreferenceClickOuter svg{
    font-size: 10px;
    color: #333333;
}

.preferenceTableOuter{
    width: 100%;
    float: left;
    text-align: lefts;
}
.preferenceTableOuter td, th{
    text-align: left !important;
    padding: 5px 12px;
}

.preferenceTableOuter thead{
    margin: 10px 0  ;
}
.preferenceEmpData{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    margin: 8px 0;
}

.preferenceEmpDataInner{
    
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 100%;
    overflow: hidden;
}
.preferenceEmpDataInner img{
    width: 100%;
    height: 30px;
    object-fit: cover;
}
.preferenceEmpData h1{
    font-size: 12px;
    color: #333333 !important;
    padding-left: 10px;
}
.preferenceTableHeading{
    background-color: #ededed;  
}
.preferenceTableHeading p{
    padding: 5px 0;
    color: #333;
}

.preferenceHours{
    font-size: 15px;
}
.preferenceHours p{
    font-size: 12px;
    font-weight: 600;
}
.preferenceShifts p{
    font-size: 12px;
    font-weight: 600;
}
.AvailabilityPopUp .MuiDialogContent-root{
padding: 20px 5px !important;
}
.newSLideDayHeading{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.newSLideDayHeading b{
    font-size: 17px;
    font-weight: 700;
    color: #990000;  
    line-height: 27px !important;
}
.newSLideDayHeading span{
    font-size: 12px;
    font-weight: 500;
    color: #333;
}
.stickyDays{
    position: sticky;
    top: -5px;
    background: #fff;
    z-index: 99;
}
.preferanceBts{
    background-image: linear-gradient(#ff4848, #990000) !important;
}
.addAvailasbilityAccordinMain{
    width: 100%;
    float: left;
}
.addAvailabiliLabel{
    font-weight: 400;
    font-size: 10px;
    color: #333333;
    margin-bottom: 10px;
    float: left;
    width: 100%;
    padding: 10px 15px 5px;
}
.addAvailasbilityProDiv{
    width: 100%;
    float: left;
    display: flex;
    gap: 13px;
    overflow-x: auto;
    padding: 0 15px;
    margin-bottom: 15px;
}
.addAvailasbilityProInrOt{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.addAvailasbilityProInrOt h5{
    font-size: 10px;
    color: #000;
    font-weight: 700;
    white-space: nowrap;
}
.addAvailasbilityProInr{
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
}
.addAvailasbilityProInr img{
    width: 100%;
    height: 40px;
    object-fit: cover;
}

.dayInD span{
    width: 100%;
    height: 5px;
    background: #990000;
    position: absolute;
    left: 0;
    bottom: -10px;
    border-radius: 50px;
    transition: .3s;
    width: 0;
}

.activeIndicator span{
    width: 100%;
}
.addAvailasbilityAccordinMain .schedulAcordianInr{
    border-radius: 0;
}
.addAvailasbilityAccordinMain .schedulAcordianTp .MuiTypography-root{
    font-weight: 600;
    font-size: 15px;
    color: #333333;
}
.addAvailasbilityAccordinMain .availabilityTpNew span {
    font-size: 13px;
    color: #990000;
    font-weight: 500;
}
.addAvailasbilityAccordinMain .schedulAcordianTpRight .schedulAcordianTpRiTx {
    font-size: 13px;
    font-weight: 700;
    color: #333;
}
.addAvailasbilityAccordinMain .dayWInr span {
    font-weight: 400;
    font-size: 14px;
    color: #868686;
}



.schedulPreviewTblMain .table-wrapper {
    overflow-x: auto;
    max-width: 100%;
    display: block;
}

  /* Table styles */
  .schedulPreviewTblMain .table-wrapper table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Ensure columns have equal width */
  }

  .schedulPreviewTblMain .table-wrapper th {
    /* border: 1px solid #9e0000; */
    padding: 8px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .schedulPreviewTblMain .table-wrapper td{
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* Set specific column widths */
  .schedulPreviewTblMain .table-wrapper th {
    width: 125px; /* Adjust width as needed */
  }
  .schedulPreviewTblMain .table-wrapper td{
    width: 70px; 
  }
  .schedulPreviewTblMain .table-wrapper th:first-child {
    width: 70px;
    border: 1px solid #fcfcfc;
    border-bottom: 1px solid #e4e4e4;
    background: #000000;
    }
    .schedulPreviewTblMain .table-wrapper td:first-child{
        width: 70px;
    }

  /* Fixed first column styles */
  .schedulPreviewTblMain .table-wrapper .fixed-column {
    position: sticky;
    left: -1px;
    background-color: #fcfcfc;
    z-index: 1;
    font-weight: bold;
    border-right: 1px solid #eee;
  }

  /* Header row styles */
  .schedulPreviewTblMain .table-wrapper th {
    background-color: #353535;
    color: white;
  }

  /* Row odd/even colors */
  /* .table-wrapper tr:nth-child(odd) {
    background-color: #f9f9f9;
  }

  .table-wrapper tr:nth-child(even) {
    background-color: #f1f1f1;
  } */
  .swapEmpDiv{
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .swapEmpDiv h1{
    font-weight: 600;
    font-size: 13px;
    color: #0FB000;
    display: flex;
  }
  .swapEmpDiv h1 svg{
    font-size: 16px;
    color: #F0B105;
  }
  .swapEmpDiv .sbIcon{
    display: none;
  }
  .swapEmpDiv span{
    font-weight: 600;
    font-size: 10px;
    color: #0FB000;
    background: #F4F4F4;
    border-radius: 50px;
    padding: 2px 4px;
  }
  .tblSearchIcoOt{
    color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tblSearchIcoOt svg{
    font-size: 30px;
  }
  .standBy h1{
    color: #868686;
  }
  .standBy span{
    display: none;
  }
  .sbIcon{
    color: #990000;
  }
  .standBy .sbIcon{
    display: block;
  }
  .assignedShiftDivMain{
    float: left;
    width: 100%;
    padding: 0 15px 15px;
  }
  .assignedShiftInr{
    width: 100%;
    float: left;
    border: 1px solid #ccc;
    border-radius: 12px;
    padding: 15px;
    margin-bottom: 15px;
  }
  .assignedShiftInr .Mui-checked{
    color: #990000 !important;
  }
  .assignShiProLine{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .assignShiProLineInr{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .assignShiProLineInr h1{
    font-weight: 500;
    font-size: 13px;
    color: #333;
  }
  .assignShiPro{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
  }
  .assignShiPro img{
    width: 100%;
    height: 40px;
    object-fit: cover;
  }
  .assignedShiftInr .dayWInr .MuiTypography-root{
    color: #333;
    font-weight: 800;
    font-size: 14px;
  }
  .assignedShiftInr .dayWInr{
    margin-bottom: 12px;
  }
  .relesEmpIco{
    color: #FA2E2E;
  }
  .activShif{
    border: 1px solid #0FB000;
    background: #F4FFEB;
  }
  .modNewBtnOuter{
    width: 100%;
    float: left;
    text-align: center;
  }
  .modNewBtnOuter button{
    background: #990000;
    width: 100%;
    height: 35px;
    border-radius: 50px;
    color: #fff !important;
    font-weight: 700;
    font-size: 16px;
  }
  .modNewBtnOuter button:hover{
    background: #990000;
  }
  .changeShifLine{
    width: 100%;
    float: left;
    margin-bottom: 15px;
  }
  .changeShifLine p{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    font-weight: 700;
    font-size: 13px;
    margin-bottom: 8px;
  }
  .changeShifLine p svg{

  }
  .previewScheduModMain .schedularAccordianMain{
    margin-bottom: 0;
  }

select {
    background: url("../images/selectIcon.svg") no-repeat !important;
    background-position: calc(100% - 0.75rem) center !important;
    appearance: none !important;
    padding-right: 2rem !important;
}
.unAvailableEmp{
    color: #FA2E2E !important;
    font-size: 18px !important;
}

.schedPreviewSlideMain .stickHeading{
    top: 55px;
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: center;
}
.schedPreviewSlideMain .slick-prev:before, .slick-next:before{
    color: #fff;
}
.schedPreviewSlideMain .slick-prev{
    top: 17px;
}
.schedPreviewSlideMain .slick-next{
    top: 17px;
}
.schedPreviewSlideMain .slick-dots{
    top: -28px;
}
.tblSearchEmp{
    position: relative;
    z-index: 9;
    margin-top: -41px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}
.tblSearchEmp svg{
    position: absolute;
    color: #767676;
    font-size: 28px;
    top: 7px;
    left: 15px;
}
.tblSearchEmp input{
    width: 100%;
    background: #fff;
    border: none;
    height: 40px;
    padding: 0 15px 0 47px;
    outline: none;
    font-size: 15px;
    font-weight: 600;
    color: #333;
}
.searchSlideClass{
    margin: 0;
    opacity: 1;
    visibility: visible;
}
.alrdLoc{
    font-size: 14px;
    font-weight: 600;
    color: #333;
}
.assignedShi{
    width: 100%;
    float: left;
    margin-bottom: 11px;
    font-size: 11px;
    font-weight: 400;
    color: #868686;
}
.changeShifLine p em{
    color: #FA2E2E;
    display: flex;
    font-size: 11px;
    font-style: normal;
    align-items: flex-start;
}
.changeShifLine p em svg{
    font-size: 18px;
}
.swapDataMain{
    width: 100%;
    float: left;
    background: #F0B105;
    padding: 15px;
    color: #ffff;
    position: relative;
}
.swpIc{
    position: absolute;
    left: 3px;
    top: 50%;
    margin-top: -38px;
    font-size: 25px;
}
.swapDatTop{
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 20px;
    position: relative;
}
.swapDatTop::before{
    content: "";
    position: absolute;
    width: 1px;
    height: calc(100% - 52px);
    border-left: 1px solid #fff;
    left: -1px;
    top: 28px;
}
.swapDatInr{
    width: 100%;
    float: left;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 10px;
    position: relative;
}
.swapDatInr::before{
    content: "";
    position: absolute;
    width: 20px;
    height: 1px;
    border-bottom: 1px solid #fff;
    left: -21px;
    top: 25px;
}
.swapDatInr .assignShiProLine{
    margin-bottom: 0;
}
.swapDatInr .assignShiProLineInr h1{
    color: #fff;
    font-size: 12px;
    font-weight: 600;
}
.swapDatInr .assignShiPro{
    width: 30px;
    height: 30px;
}
.swapDatInr .assignShiPro img{
    height: 30px;
}
.swapShiRight{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
}
.swapShiRight h1{
    font-weight: 400;
    font-size: 11px;
}
.swapShiRight p{
    font-weight: 400;
    font-size: 12px;
}
.rejNAcepBts{
    width: 100%;
    float: left;
    display: flex;
    margin-top: 15px;
    justify-content: center;
    gap: 15px;
}
.rejNAcepBts button{
    background: #fff;
    border-radius: 50px;
    height: 30px;
    padding: 0 15px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 700;
    color: #FA2E2E;
    width: 100%;
    max-width: 150px;
}
.rejNAcepBts button:hover{
    background: #fff;
}
.shiftAlertMsg{
    width: 100%;
    float: left;
    font-size: 12px;
    font-weight: 500;
    color: #0FB000;
    margin-bottom: 15px;
    display: flex;
    gap: 8px;
    line-height: 18px !important;
    text-transform: capitalize;
    display: none;
}
.assignedShiftInr .dayWInr span{
    color: #000;
    font-weight: 800;
    font-size: 15px;
}

.relesD{
    color: #990000;
    display: flex;
    font-size: 11px;
    font-style: normal;
    align-items: flex-start;
    font-weight: 700;
}
.relesD svg{
    font-size: 18px;
    color: #990000;
}
.SiftAssignToBt{
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #990000 !important;
    text-transform: capitalize !important;
    border: 1px solid #990000 !important;
    border-radius: 50px !important;
    height: 30px;
    padding: 0 15px !important;
}
.assigPlac{

}
.assigPlac .assignShiPro{
    border: 3px solid #990000;
}
.assigPlac h1{
    color: #000;
    font-weight: 700;
    font-size: 14px;
}
.forNotificationMain{
    width: 100%;
    float: left;
    padding: 0 15px;
    margin-bottom: 15px;
}
.forNotificationMain .slick-dots{
    top: 7px;
    bottom: unset;
    display: flex !important;
    justify-content: center;
    padding: 0 9px;
    left: 25px;
    width: calc(100% - 45px);
}
.forNotificationMain .slick-dots li{
    width: 15px;
    margin: 0;
}
.notificationMain{
    width: 100%;
    float: left;
    background: #EEEEEE;
    border-radius: 10px;
    padding: 15px;
    padding-top: 20px;
    position: relative;
    padding-top: 35px;
    margin-bottom: 15px;
}
.forNotiTablet{
    width: 100%;
    display: inline-block;
    text-align: center;
}
.forNotiTablet .notificationMain{
    text-align: left;
}
.forNotiTablet .swapShiRight{
    display: block;
}
.notificationTp{
    display: flex;
    gap: 10px;
}
.notificationProp{
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    border-radius: 100%;
    overflow: hidden;
}
.notificationProp img{
    width: 100%;
    height: 30px;
    object-fit: cover;
}
.notificationMain h1{
    font-weight: 500;
    font-size: 13px;
    color: #000000;
    line-height: 21px !important;
}
.notificationMain h1 span{
    font-weight: 400;
    font-size: 13px;
    color: #868686;
}
.notificationMain h1 b{
    font-weight: 500;
    font-size: 13px;
    color: #333333;
}
.notificationMain h1 em{
    font-weight: 700;
    font-size: 13px;
    color: #990000;
}
.notificationMain .swapDataMain{
    padding: 0;
    background: no-repeat;
    margin-top: 12px;
}
.notificationMain .swapDatInr{
    background: #fff;
    border: 1px solid #797979;
}
.notificationMain .swpIc{
    left: -12px;
    color: #797979;
    z-index: 9;
}
.notificationMain .swapDatTop::before{
    border-left: 1px solid #797979;
    height: calc(100% - 57px);
    left: -1px;
    top: 27px;
}
.notificationMain .swapDatInr::before{
    border-bottom: 1px solid #797979;
}
.notificationMain .swapDatInr .assignShiProLineInr h1{
    color: #333;
}
.notificationMain .swapShiRight p {
    color: #333;
}
.schedPreviewSlideMain .stickHeading button{
    text-transform: capitalize;
    font-size: 11px;
    color: #888888;
    gap: 3px;
    height: 15px;
}
.schedPreviewSlideMain .stickHeading button svg{
    font-size: 12px;
}
.wantToSwapMain{
    display: flex;
    flex-direction: row;
    gap: 0;
    border: 1px solid #b6b6b6;
    border-radius: 9px;
    padding: 8px;
    justify-content: space-between;
}
.wantToSwapMain .swapDatInr{
    padding: 0;
    border: none;
    background: none;
}
.wantToSwapMain .swapShiRight h1{
    font-size: 9px;
    font-weight: 700;
}
.wantToSwapMain .swapShiRight p{
    font-size: 10px;
    font-weight: 600;
}
.wantToSwapMain .swapDatInr::before{
    display: none;
}
.wantToSwapMain .swapDatInr:first-child .swapShiRight{
    align-items: flex-start;
}
.wantToSwapMain .swapDatInr:last-child .swapShiRight{
    align-items: flex-start;
}
.wantToSwapMain .swapDatInr:last-child .assignShiProLine{
    justify-content: flex-end;
}
.wantToSwapMain .swpIc{
    left: auto;
    right: -14px;
    top: auto;
    margin-bottom: -10px;
    bottom: 50%;
}
.slick-dots li.slick-active button:before {
    color: #970000;
}

.schedledLin{
    width: 100%;
    float: left;
    margin-bottom: 15px;
    padding: 0 15px;
    font-weight: 400;
    font-size: 13px;
    color: #333333;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.swapOfrBtnOuter{
    width: 100%;
    float: left;
    padding: 10px 15px;
    top: 95px;
    position: sticky;
    background: #fff;
    z-index: 3;
}
.swapOfrBtnMain{
    width: 100%;
    float: left;
    background: #F3F3F3;
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 2px;
}
.swapOfrBtnMain .MuiToggleButtonGroup-root{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.swapOfrBtnMain .MuiToggleButtonGroup-root button{
    width: 100%;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    border-radius: 6px;
    border: none;
    padding: 7px 10px;
    gap: 7px;
}
.swapOfrBtnMain .Mui-selected{
    background-color: #333333 !important;
    color: #fff !important;
}
.swapOfrBtnMain .MuiToggleButtonGroup-root button:first-child::before{
    content: "";
    width: 1px;
    height: 100%;
    border-left: 1px solid #ccc;
    right: -3px;
    position: absolute;
}

.swpWt{
    display: none;
}
.swapOfrBtnMain .Mui-selected .swpBlc{
    display: none;
}
.swapOfrBtnMain .Mui-selected .swpWt{
    display: block;
}
.forSwpCk{
    width: 100%;
    float: left;
}
.selctedSwap{
    background: linear-gradient(to bottom, #ffffff 0%, #2fda4f 100%);
    border-radius: 11px;
}
.selctedSwap .swapEmpDiv h1{
    color: #000;
}
.selctedSwap .swapEmpDiv span{
    background: #0b6317;
    color: #ffffff;
}
.swapDrawerMain{
    position: fixed;
    width: 100%;
    padding: 15px;
    background: #333333;
    color: #fff;
    top: 100%;
    z-index: 3;
    border-radius: 25px 25px 0 0;
    transition: .3s;
    border-top: 10px solid #fff;
    min-height: 240px;
}
.addSwap .swapDrawerMain{
    margin-top: -115px;
}
.indicatSwapHead{
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    display: flex;
    justify-content: center;
    gap: 6px;
    margin-bottom: 20px;
}
.openBotDrawCl .swapDrawerMain{
    bottom: 55px;
    top: auto;
}
.drwrIcoArro{
    transition: .3s;
}
.openBotDrawCl .drwrIcoArro{
    transform: rotate(180deg);
}
.swapDrawerMain .swapDataMain{
    background: transparent;
    padding: 0;
}
.swapDrawerMain .swpIc{
    left: -12px;
}
.underProcessDataMain{
    width: 100%;
    float: left;
    padding: 0 15px 0 20px;
    /* border-left: 1px solid #fff; */
    position: relative;
}
.underProcessDataMain::after{
    content: "";
    position: absolute;
    width: 1px;
    background: #e4e4e4;
    /* height: 100%; */
    height: calc(100% - 25px);
    left: -2px;
    top: 8px;
}
.underProcessDataMain h4{
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 10px;
    position: relative;
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
}
.handBy{
    animation: wave 2s infinite ease-in-out;
}
.swapAnimHand{
    animation: wave 2s infinite ease-in-out;
}
@keyframes wave {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(15deg);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-15deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
.underProcessDataMain h4::before{
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background: #3ac240;
    border-radius: 100%;
    left: -27px;
    top: 6px;
    animation: pulse-animation 2s infinite;
    z-index: 9;
}
.underProcessDataMain .swapDatInr{
    margin-bottom: 20px;
}
.hideD1{
    display: none;
}
.hideShowdrwrSt .hideD1{
    display: block;
}
.hideShowdrwrSt .hideD2{
    display: none;
}
.patchForSwapDraw{
    float: left;
    width: 100%;
    height: 50px;
}
.ediTaskListMain{
    width: 100%;
    float: left;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.ediTaskListMain:last-child{
    border: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.ediTaskListAllSele{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ediTaskListAllSele .MuiFormControlLabel-label{
    font-weight: 600;
    font-size: 16px;
    color: #990000;
}
.ediTaskListMain .Mui-checked{
    color: #990000 !important;
}
.ediTaskListMain .MuiTypography-root{
    font-size: 14px;
    font-weight: 500;
}
.ediTaskListAddedShif{
    width: 100%;
    float: left;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ediTaskListAddedShif p{
    font-size: 11px;
    color: #000;
    font-weight: 500;
}
.noShiftAdded{
    color: #acacac !important;
    font-weight: 400 !important;
}
.seleLabelD .MuiButtonBase-root{
    text-transform: capitalize;
    font-weight: 400;
    font-size: 12px;
    color: #868686;
}
.seleLabelDIn .MuiPaper-rounded{
    border-radius: 20px;
    box-shadow: none;
    border: 2px solid #eee;
}
.verticlCheckMain{
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
}
.verticlCheckMain .Mui-checked{
    color: #990000 !important;
}
.labelDropMain{
    width: 100%;
    float: left;
}
.labelDropHead{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid #ccc;
    height: 52px;
    padding: 0 15px;
    font-size: 15px;
    font-weight: 500;
    color: #000000;
}
.labDropCont{
    width: 100%;
    float: left;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 10px;
    margin-top: -100%;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    background: #fafafa;
}
.labDropClass{
    margin-top: 0;
    opacity: 1;
    visibility: visible; 
}
.labDropContInr{
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 13px;
    margin-bottom: 13px;
    border-bottom: 1px solid #ccc;
}
.labDropContInr:last-child{
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
}
.labDropContInr h1{
    width: 100%;
    float: left;
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    display: flex;
    align-items: center;
    gap: 10px;
}
.labDropContInr h1 em{
    width: 10px;
    height: 10px;
    background: #0FB000;
    float: left;
    border-radius: 100%;
}
.labDropContInr p{
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    gap: 9px;
}
.labDropContInr p svg{
    font-size: 20px;
    color: #0fb000;
}
.labelDropHeadLab {
    display: flex;
    align-items: center;
    gap: 10px;
}
.labelDropHeadLab em{
    width: 13px;
    height: 13px;
    display: flex;
    border-radius: 100%;
    background: #0fb000;
}
.pulse {
    animation: pulse-animation 2s infinite;
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }

.videoPlaMain{
    width: 100%;
    float: left;
    height: 85px;
    overflow: hidden;
    border-radius: 5px;
}

.mainDetailsDiv{

}
.mainDetailsDiv .videoPlaMain{
    width: 48% !important;
    border: 2px solid #b6b6b6;
}
.videoPlaMain img{
    width: 100%;
}

.videoLoaderMain{
    width: 100%;
    background: #27272c;
    position: fixed;
    left: 0;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.videoLoaderMain img{
    width: 100%;
}
.videoLoaderMain h1{
    width: 100%;
    float: left;
    color: #fff;
    font-size: 21px;
    text-align: center;
    line-height: 32px !important;
    font-weight: 400;
}
.assignedShif {
    background: #f2fff6;
    border: 1px solid green;
}
.assignedShif .dayWInr b{
    color: green;
    font-size: 13px;
}
.assignedShif .dayWInr svg{
    font-size: 20px;
    color: green;
}
.creatNewSchedDropMenu{
    width: 100%;
    float: left;
    margin-bottom: 13px;
}
.creatNewSchedDropMenu select{
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 40px;
    padding: 0 15px;
    color: #333;
    font-size: 14px;
    outline: none;
}
.newAddedTempTop{
    display: block;
    border-bottom: none !important;
    border: 1px solid #eee !important;
    border-radius: 10px;
    padding: 7px;
    position: relative;
    margin-bottom: 16px;
    padding-top: 13px;
}
.newAddedTempTop em{
    position: absolute;
    top: -7px;
    background: #ffdbdb;
    color: #1c1c1c;
    padding: 2px 9px;
    border-radius: 4px;
    font-weight: 500;
}
.newAddedTempTopInr{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}
.weekShifNameLab{
    font-size: 8px;
    font-weight: 800;
    background: #eee;
    padding: 5px 2px;
}
.checkListSlidMain .slick-prev{
    left: 0;
}
.checkListSlidMain .slick-next{
    right: 0;
}

.tblSwitch .MuiSwitch-track{
    background-color: #a9a9a9;
}
.tblSwitch .Mui-checked+.MuiSwitch-track{
    background-color: #24a323 !important;
}
.tblSwitch .Mui-checked .MuiSwitch-thumb{
    background-color: #26c425;
}

.DetailPreviewTbl{
    display: none;
}
.showpreTbl .DetailPreviewTbl{
    display: inline-table;
}
.showpreTbl .onlyPreviewTbl{
    display: none;
}
.sbMod{
    color: #F0B105 !important;
}

.newSBModColor .sbMod{
    color: #055091 !important;
}
.withPlusIcon{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}
.addTskIco{
    background-color: #9d0a0a !important;
    border-radius: 100% !important;
    height: 48px;
    color: #fff !important;
    width: 49px;
}
.withPlusIcon .tabMickMainInpnew{
    margin-bottom: 0 !important;
}
.docUpladPreviewMain{

}
.docUpladPreviewMain .uploadedImInr{
    width: 80px;
    height: 80px;
    border: 2px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
}
.docUpladPreviewMain .uploadedImInr img{
    width: 49px;
}
.tblSwitch {
    position: sticky;
    top: 0;
    z-index: 9;
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
}
.tblSwitch .MuiSwitch-root{
    background: #171717;
    border-radius: 50px 0 0 50px;
}
.toggleTblSwitch{
    position: sticky;
    top: 57px;
}
.alrdAssignedView{
    width: 100%;
    float: left;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    position: relative;
    padding-top: 25px;
}
.alrdAssignedHead{
    position: absolute;
    top: -7px;
    left: 9px;
    background: #f3f3f3;
    padding: 2px 9px;
    font-size: 12px;
    border-radius: 3px;
}
.alrdAssignedView h1{
    font-size: 13px;
    font-weight: 900;
    color: #333;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
}
.alrdAssignedView h1 svg{
    font-size: 15px;
}
.alrdAssignedView h2{
    font-size: 13px;
    font-weight: 600;
    color: #333;
    display: flex;
    align-items: center;
    gap: 4px;
}
.alrdAssignedView h2 svg{
    font-size: 15px;
}
.assignedEmpName{
    width: 100%;
    float: left;
    margin-top: 15px;
}
.assignedEmpName p{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 9px;
}
.assignedEmpName p b{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 13px;
    font-weight: 600;
}
.assignedEmpName p b span{
    width: 8px;
    height: 8px;
    background: #2f9717;
    display: block;
    border-radius: 100%;
}
.assignedEmpName p em{
    color: #FA2E2E;
    display: flex;
    font-size: 11px;
    font-style: normal;
    align-items: center;
    gap: 1px;
}
.assignedEmpName p em svg{
    font-size: 14px;
}
.extrHeading{
    margin-bottom: 15px;
    color: #333;
    font-size: 19px;
    margin-top: -24px;
}
.inerSumryHeading{
    width: 100%;
    float: left;
    font-size: 18px;
    margin-bottom: 15px;
}
.sumryInrDi{
    width: 100%;
    float: left;
    margin-bottom: 15px;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 15px;
    padding-left: 1px;
    padding-right: 1px;
}
.sumryInrDi:last-child{
    border-bottom: none;
    padding-bottom: 0;
}
.sumryInrDi .progBarMain{
    overflow: hidden;
}
.activeRow{
    background: #89ff9f;
}
.activeRow .fixed-column{
    background: #89ff9f !important;
}
.activeRow h1{
    color: #333 !important;
}
.ShiftSwapAndOffer .schedPreviewSlideMain .stickHeading{
    justify-content: space-between;
}
.ShiftSwapAndOffer .swapOfrBtnOuter{
    top: 107px;
}
.topBarMain{
    width: 100%;
    float: left;
    height: 55px;
    background: #fff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    padding: 0 15px;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
}
.notiSt .MuiBadge-badge{
    background: #990000;
    color: #fff;
    top: 4px;
    border: 2px solid #fff;
}
.notiSt .MuiSvgIcon-root{
    font-size: 26px;
    color: #303030;
}
.innerPagesMain{

}
.innerPagesMain .innerPageTopSec{
    top: 0;
}
.innerPagesMain .innerPageTopSecBackPatch{
    height: 75px;
}
.notificationPageContMain{

}
.dayOfNotification{
    font-size: 16px;
    margin-bottom: 10px;
    color: #333;
    width: 100%;
    float: left;
}
.notiNewOutDi{
    /* border: 1px solid #e2e2e2; */
    border-radius: 9px;
    /* overflow: hidden; */
    /* display: block; */
}
.notificationInrData{
    width: 100%;
    float: left;
}
.notificationInrData h1{
    width: 100%;
    float: left;
    font-size: 13px;
    font-weight: 600;
    color: #000;
    line-height: 15px !important;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 5px;
}
.notificationInrData h1 span{
    color: #acacac;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 11px;
}
.notificationInrData h1 span em{
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: 5px;
}
.notificationInrData h1 span svg{
    font-size: 12px;
}
.notificationInrData p{
    width: 100%;
    float: left;
    font-size: 12px;
    font-weight: 400;
    color: #888888;
    line-height: 18px !important;
}
.notificationInrData p b{
    color: #818181;
    text-transform: capitalize;
}
.notificationPageContMain .emploTaskListInr{
    background: #fff;
    border: none;
    border-radius: 8px;
    border: 1px solid #ccc;
    border-left: 3px solid #4caf50;
    position: relative;
}
.notiFicClose{
    position: absolute !important;
    right: 0px;
    top: 3px;
}
.notiFicClose svg{
    font-size: 18px;
}
.notificationPageContMain .swipe-container{
    /* border-bottom: 1px solid #ccc; */
    float: left;
}
.notificationPageContMain .swipe-container:last-child{
    border: none;
}
.notificationPageContMain .swiped-right .emploTaskListInr{
    white-space: normal;
}
.notificationPageContMain .swiped-left .emploTaskListInr{
    white-space: normal;
}

.notiFDDates{
    font-size: 12px;
    font-weight: 600;
    color: #ccc;
    margin-top: 9px;
    float: left;
    width: 100%;
    text-align: right;
}
.notificationInner{
    position: relative;
}
.notificationInner .MuiButtonBase-root svg{
    font-size: 32px;
    color: #747474;
}
.mobNotiFicMain .MuiPaper-elevation {
    width: 100%;
}
.mobNotiFicMain .MuiPaper-rounded {
    border-radius: 7px 7px 20px 20px;
    background: #990000;
    top: 49px !important;
    position: relative;
    overflow-x: initial;
    overflow-y: initial;
    border-bottom: 10px solid #b8292f;
    border-left: 10px solid #b8292f;
}
.mobNotiFicMain .MuiPaper-rounded::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #990000;
    position: absolute;
    top: -8px;
    right: 6px;
}
.mobNotiFicMain .MuiButtonBase-root{
    color: #fff;
    border-bottom: 1px dashed #ce363d;
}
.mobNotiFicMain .MuiButtonBase-root:last-child{
    border: none;
}
.mobNotiFicMain .MuiButtonBase-root em{
    position: absolute;
    width: 7px;
    height: 7px;
    background: #62ff69;
    top: 19px;
    right: 13px;
    border-radius: 100%;
    box-shadow: 0px 0px 10px #94ff94;
}
.notiCounts{
    padding: 2px 5px;
    background: #ff4444;
    color: #fff;
    font-weight: 500;
    border-radius: 50px;
    position: absolute;
    font-size: 12px;
    top: 4px;
    right: -1px;
    border: 3px solid #fff;
}
.reportAdminMain .mySlik .slick-list{
    height: auto;
}
.indicatAdminIco{
    display: none;
}
.leftBorderAdmin .indicatAdminIco{
    display: block;
    margin-bottom: 11px;
}
.indicatAdminIco{
    width: 9px;
}
.costomArrowMain{
    width: 100%;
    float: left;
    position: relative;
}
.costomArrowMain .slikArLeft{
    top: 0;
}
.costomArrowMain .slikArRight{
    top: 0;
}

.templateError{
    width: 100%;
    float:left;
    border: 1px solid red;
    border-radius: 10px;
    padding: 20px;
    color: red;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px !important;
    background-color: #ffeded;
}
.alignCheckBx .MuiFormControlLabel-root{
    width: 50%;
    margin: 0;
    margin-bottom: 12px;
}
.alignCheckBx .MuiFormControlLabel-root .MuiTypography-root{
    font-size: 12px;
    font-weight: 600;
    margin-right: 10px;
    text-transform: uppercase;
}
.alignCheckBx .MuiCheckbox-root{
    padding: 0;
    margin-right: 6px;
}
.swal-modal .swal-text{
    text-align: center;
    line-height: 27px !important;
}
.swal-modal .swal-footer{
    text-align: center;
}
.swal-button--danger {
    background-color: #e64942 !important;
}
.myBlankDataMain{
    display: flex;
    align-items: center;
    gap: 5px;
}
.employyeLisContRightNewDiv {
    align-items: flex-end;
    height: 52px;
    justify-content: space-between;
}


/* added by vaishali  */

.assignTaskP{
    padding-bottom: 10px;
    color: #333333;
    font-size: 16px;
    font-weight: 500;
}
.pdBottom{
    padding-bottom: 0 !important;
}
.shiftStatusCss{
    font-weight: 600;
    color: #F0B105 !important;
}
.shiftStatusCssU{
    font-weight: 600;
    color: red !important;
}
.cardStatusSB{
    background-color: #ffefc4;
    border: 2px solid #F0B005;
}
.newStandbySpan{
    color: #F0B105 !important; 
}
.newAssignSpan{
    color: #0FB000 !important;
 }
 .AssignedStatus{
    color: #007c00 !important;
 }
 .UnassignedStatus{
    color: red !important;
 }
 .standbyHeadColor{
    color: #333333 !important;
 }
 .categorySwitchView{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.categorySwitchView p{
    margin-right: 15px;
    font-weight: 400;
    font-size: 1rem;
    color: #4c4c4c;
}

.myClass{
    background: red !important;
    color: #fff;
}
.locTime{
    margin: 3px 0;
    color: #333 !important;
}
.emploShifTb{
    align-items: center;
}
.emploShifTb .MuiFormControlLabel-root{
    margin: 0;
}
.emploShifTb .Mui-checked {
    color: #990000 !important;
}
.emploShifTb .Mui-checked+.MuiSwitch-track {
    background-color: #990000 !important;
}
.emploShifTb .PrivateSwitchBase-input{
    width: 100% !important;
    left: 0 !important;
}
.emploShifTb .MuiSwitch-root{
    margin-right: -10px;
}
.extraErrorCss{
    margin-top: -8px;
    margin-bottom: 10px;
}
.selectSwapOffLab{
    width: 100%;
    float: left;
    text-align: center;
}
.blink_Label {
    animation: blinker 2s linear infinite;
    background: #89ff9f !important;
    padding: 7px 10px;
    display: inline-flex;
    border-radius: 6px;
    color: #000;
    margin-bottom: 10px;
    position: relative;
  }

  .blink_Label::before{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #90ffa5;
    bottom: -5px;
    left: 50%;
    margin-left: -6px;
    animation: blinker 2s linear infinite;
  }
  .repoSllider{

  }
    .repoSllider .slick-dots {
    top: 0;
    }
    .repoSllider .slick-slider{
        padding-top: 36px;
    }
    .repoSllider .slidDayHeading{
        margin-bottom: 20px;
    }
    .repoDate{
        margin-top: 8px;
        font-weight: 800;
        font-size: 14px;
    }
    .filesTailMain{
        width: 100%;
        float: left;
        background: #f5fffc;
        border-radius: 10px;
        padding: 10px;
        border: 2px dashed #ccc;
    }
    .filesTailMain .uploadedImInr{
        border: 2px solid #b7b7b7;
    }

    .newTaskDetailMain .tskHeading{
        color: #990000;
        font-size: 19px;
    }
    .tskDiscrip{
        width: 100%;
        float: left;
        white-space: normal !important;
        word-wrap: break-word;
        line-height: 22px !important;
        font-size: 13px;
        color: #333;
        margin-bottom: 10px;
    }
    .swal-button {
        background-color: #990000 !important;
    }
    .swal-icon--info {
        border-color: #9900001c !important;
    }
    .swal-icon--info:after, .swal-icon--info:before{
        background-color: #9900001c !important;
    }
    .swal-button-container .swal-button--cancel {
        color: #555;
        background-color: #efefef !important;
    }
    .timingAddLeft .rs-input-group.rs-input-group-inside .rs-input-group-addon{
        padding: 10px 8px 10px 0;
        font-size: 11px;
    }
    .deacivatedEmployee{
        background: #fff4f4 !important;
        border-left: 5px solid red !important;
    }
    .deacivatedEmployee .employyeLisCont h1{
        color: #ffb2b2;
    }
    .deacivatedEmployee .employyeLisCont span{
        color: #ffb2b2;
    }
    .deacivatedEmployee .employyeLisCont p{
        color: #ffb2b2;
    }
    .deacivatedEmployee .employyeLisContRight span{
        color: #ffb2b2;
    }
    .openDropMain{
        width: 100%;
        float: left;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 15px;
    }
    .openDropMain .MuiSvgIcon-root{
        fill: rgb(153 0 0);
    }
    .openDropInr{
        overflow-y: auto;
        max-height: 134px;
        width: 100%;
        float: left;
    }
    .shiftStatusCssU .MuiTypography-root{
        font-weight: 600;
        color: red !important;
    }
    .shiftStatusCss .MuiTypography-root{
        font-weight: 600;
        color: #F0B105 !important;
    }
    .notifLocation{
        position: absolute;
        top: -8px;
        color: #000;
        background: #ffc8c8;
        border-radius: 3px;
        font-size: 11px;
        font-weight: 600;
        padding: 2px 5px;
        border: 1px solid #f9a6a6;
    }
    .swpDtNew{
        padding-top: 15px;
        background: #fbfbfb;
        border: none;
    }
    .statusCls{
        position: absolute;
        top: 6px;
        right: 6px;
        font-size: 19px !important;
        z-index: 9999;
    }
    .statusPenOrRej{
        position: absolute;
        background: #990000;
        padding: 2px 5px;
        border-radius: 5px;
        font-size: 11px;
        color: #fff;
        top: 8px;
        font-weight: 700;
    }
.pendingStaus{
    background: #ff9a52 !important;
}
.acceptedStatus{
    background: #17b90c !important;
}
.approvedStatus{
    background: #17b90c !important;
}
.rejectedStatus{
    background: #ff2222 !important;
}
.forSingleShift{
    justify-content: flex-start !important;
}
.forSingleShift .swapShiRight{
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: space-between;
    width: 100%;
}
.forSingleShift .swapShiRight h1{
    display: flex;
    align-items: center;
    gap: 5px;
}
.forSingleShift .swapShiRight p{
    display: flex;
    align-items: center;
    gap: 5px;
}
.forSingleShift .swapShiRight svg{
    font-size: 17px;
}
.shifViewMain{
    width: 100%;
    float: left;
    background: #000000;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 12px 15px;
    transition: .2s;
    margin-top: -80px;
    opacity: 0;
    visibility: hidden;
}
.showShifViewSt{
    margin-top: 0;
    opacity: 1;
    visibility: visible;
}
.shifViewMain h1{
    font-size: 10px;
    display: flex;
    align-items: center;
    gap: 3px;
    font-weight: 800;
    color: #fff;
}
.shifViewMain h1 em{
    display: flex;
    align-items: center;
    gap: 2px;
    font-weight: 900;
    font-style: normal;
    font-size: 11px;
}
.shifViewMain h1 em svg{
    font-size: 18px;
}
.activeFullFill{
    color: #0bee0b !important;
}
.pendingEmp{
    color: red !important;
}
.acciveThead{
    background: #000000 !important;
}
.showHiddenRowArrow{
    position: absolute;
    left: 4px;
    top: 50%;
    margin-top: -9px;
    font-size: 20px !important;
    color: #ff9e9e;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    border-radius: 100%;
}
.tableHiddenShowArr{
    position: absolute;
    top: 9px;
    left: 9px;
}
.tableHiddenShowWeek{
    position: absolute;
    top: 9px;
    right: 9px; 
    transition: .8s; 
}
.moveWeekIco{
    top: 75px;
    right: 9px;
    background: #47b216;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: #ffff;
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.2);
    border: 3px solid #fff;
}


.columnView .MuiFormControl-root{
    width: 100%;
}
.columnView .MuiFormControl-root .MuiFormGroup-root{
    display: flex;
    flex-direction: row;
    gap: 5px 20px;
}




.hideButton {
    background-color: #f44336; /* Red color */
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 5px;
  }
  
  .hideButton:hover {
    background-color: #d32f2f;
  }

/* by vaishali consolidated preview  */
.newPreviewLabelText{
    font-size: 15px;
    font-weight: 600;
    text-wrap: auto;
    line-height: 18px !important;
    letter-spacing: 1px !important;
    font-size: 15px;
    font-weight: 600;
    text-wrap: auto;
    width: 100%;
    padding: 0 40px;
}

.scheduleLocationBg{
    background-color: #dbdbdb !important;
    color: #000;
    border-top: 1px solid #b6b6b6;
}
.empAvailabilityBg{
    background-color: #82de6a !important;
    color: #000;
    border-top: 1px solid #30a511;
}

.requiredStar{
    color: red;
}
.copmnyDeleteOuter{
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    justify-content: space-between;
    padding: 7px 12px;
}
.copmnyDeleteOuter svg{
    color: #f63636;
}
.copmnyDeleteOuter .MuiFormControlLabel-root{
    margin-right: 0;
}
.copmnyDeleteOuter .Mui-checked .MuiSwitch-thumb{
    background: #0fb000 !important;
}
.copmnyDeleteOuter .Mui-checked+.MuiSwitch-track{
    background: #0fb000 !important;
}
.bottViewMain{
    width: 100%;
    float: left;
    min-height: 100px;
    position: fixed;
    bottom: -100%;
    left: 0;
    background: #fff;
    z-index: 999;
    padding: 10px;
    border-top: 13px solid #121212;
    transition: .3s;
    height: 94%;
    border-radius: 20px 20px 0 0;
}
.openBottMainIntro{
    bottom: 0;
}
.bottViewMainClose{
    position: absolute;
    top: 17px;
    right: 14px;
    font-size: 20px !important;
}
.shiftOpenCont{
    width: 100%;
    float: left;
}
.shiftOpenCont h1{
    font-size: 15px;
    color: #ffffff;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    background: #990000;
    padding: 9px 6px;
    border-radius: 6px;
    position: sticky;
    top: -1px;
    z-index: 9;
}
.shiftOpenCont h1 svg{
    font-size: 15px;
}
.weekShowFull{
    width: 100%;
    float: left;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.weekShowFull:last-child{
    border: none;
    padding-bottom: 0;
}
.weekShowFull label{
    font-size: 13px;
    font-weight: 600;
    color: #333;
    margin-bottom: 13px;
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.weekShowFull label em{
    float: right;
    font-size: 10px;
    color: #222222;
    font-weight: 700;
    background: #eaeaea;
    padding: 1px 5px;
    border-radius: 50px;
    border: 1px solid #ccc;
}
.weekShowFull label span{
    color: #990000;
    font-size: 10px;
    margin-left: 5px;
}
.weekShowFullInr{
    width: 100%;
    float: left;
    display: flex;
    gap: 10px 10px;
    flex-wrap: wrap;
}
.weekShowFullInr h3{
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 10px;
    color: #333;
    margin: 0;
    font-weight: 800;
}
.weekShowFullInr h3 em{
    display: flex;
    align-items: center;
    gap: 4px;
}
.weekShowFullInr h3 em svg{
    font-size: 17px; 
}
.withEmpName{
    background: #f7f7f7;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    position: relative;
    padding-top: 9px;
}
.withEmpName p{
    font-size: 11px;
    color: #7b7b7b;
    font-weight: 400;
    margin-top: 2px;
}
.withEmpName p b{

}
.shiftOpenContScroll{
    width: 100%;
    float: left;
    overflow-y: auto;
    height: calc(100% - 40px);
}
.bottViewMainHeeadding{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 14px;
}
.bottViewMainHeeadding b{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    color: #333;
}
.noScheAvailabMain{
    width: 100%;
    float: left;
    margin-bottom: 15px;
    border: 1px solid #ffc7c7;
    padding: 15px;
    border-radius: 10px;
    background: #fff4f4;
    display: flex;
    gap: 5px;
}
.noScheAvailabMain svg{
    color: #ffa2a2;
}
.noScheAvailabMain span{
    color: #ff6a6a;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px !important;
}




    .schedTsBt{
        border: 1px solid #ededed !important;
        width: 100%;
        height: 65px;
        font-weight: 600 !important;
        text-transform: capitalize !important;
        font-size: 15px !important;
        color: #333 !important;
        justify-content: flex-start !important;
        padding: 15px !important;
        border-radius: 8px !important;
        background: #efefef !important;
        gap: 18px;
        margin-bottom: 14px !important;
    }
    .schedTsBtIco{
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .centerTabsAlign .MuiTab-textColorPrimary{
        width: 50% !important;
    }


    .newWeeklyFlowMain{
        width: 100%;
        float: left;
    }
    .prefranOrAvailab{
        width: 100%;
        float: left;
        height: auto;
        color: #0a0a0a;
        padding: 15px 15px 15px;
        background: #ffeb7f;
        margin-top: -2px;
        margin-bottom: 10px;
    }
    .prefranOrAvailab h1{
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    .prefranOrAvailabInr{
        float: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .prefranOrAvailabInr p{
        font-size: 15px;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .forWeeklyInnerTabsMain{
        width: 100%;
        float: left;
        position: relative;
    }
    .forWeeklyInnerTabsMain .kTab{
        position: fixed;
        bottom: 53px;
        width: 100%;
        left: 0;
        background: #e7e7e7;
        z-index: 9;
    }
    .forWeeklyInnerTabsMain .innerPageTopSecBackPatch{
        height: auto;
    }
    .forWeeklyInnerTabsMain .topBarMain{
        display: none;
    }
    .forWeeklyInnerTabsMain .innerPageTopSec{
        display: none;
    }
    .forWeeklyInnerTabsMain .innerPageTopSecBackPatch{
        height: auto !important;
    }
    .forWeeklyInnerTabsMain .adminBottomNavMain{
        display: none;
    }
    .forWeeklyInnerTabsMain .mySlik .slick-list {
        height: auto;
        margin-top: 5px;
    }
    .forWeeklyInnerTabsMain .MuiTab-textColorPrimary{
        font-size: 11px;
        font-weight: 700;
    }
    .forWeeklyInnerTabsMain .tabInrContDiv{
        padding: 0;
    }
    .forWeeklyInnerTabsMain .schedPreviewSlideMain .stickHeading {
        top: 113px;
    }
    .forWeeklyInnerTabsMain .forNotificationMain{
        margin-top: 15px;
    }
    .forWeeklyInnerTabsMain .toggleTblSwitch {
        top: 115px;
    }
    .forWeeklyInnerTabsMain .tblSwitch {
        margin-top: 11px;
    }
    .forWeeklyInnerTabsMain .listingSearch img{
        display: none;
    }
    .forWeeklyInnerTabsMain .clickAllTile{
        margin-top: -8px !important;
    }
    .padingLeftRigMinus{
        padding-left: 0;
        padding-right: 0;
    }
    .assignFirstLabel{
        background: #ffebeb;
        border: 1px solid #ffb5b5;
    }








.forTabletSliderOnly .currentWeekTx{
    display: none !important;
}
.forTabletDate{
    display: none;      
}
.currentWeekTx{
    display: none;
}
.slick-current .currentWeekTx{
    display: block;
    font-size: 11px !important;
    font-weight: 600 !important;
}
.searchEmpTx span{
    display: none;
}
.forShiftsIndicat{

}
.forShiftsIndicat b{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    align-items: center;
    gap: 5px;
    margin-top: 3px;
    display: none;
}
.forShiftsIndicat b em{
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #fff;
    display: inline-flex;
}
.actFullFill{
    color: #0FB000;
}
.actSbFill{
    color: #DBA11A;
}
.actUnAvailFill{
    color: #FA2E2E;
}

.actFullFill em{
    background: #0FB000 !important;
}
.actSbFill em{
    background: #DBA11A !important;
}
.actUnAvailFill em{
    background: #FA2E2E !important;
}


.forMultiDayAndTim{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 4px;
}
.forTaskIndicatUser{
    position: absolute;
    right: 10px;
    font-size: 20px !important;
    color: #990000;
    top: 15px;
}
.taskImUploadNewSt {
    position: absolute;
    top: 41px;
    right: 10px;
}
.browseFiNote{
    width: 100%;
    float: left;
    color: red;
    font-size: 12px;
    margin-top: 10px;
}
.withLabelRadio{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    gap: 5px;
}
.labelTxRadio{
    font-size: 12px;
    color: #990000;
    font-weight: 500;
}
.moreContMain{
    width: 100%;
    float: left;
}
.moreContInr{
    width: 100%;
    float: left;
    padding: 15px 0;
    border-bottom: 1px dashed #ccc;
}
.moreContInr:last-child{
    padding-bottom: 0;
    border: none;
}
.moreHeadi{
    width: 100%;
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}
.moreHeadiMain{
    width: 100%;
    float: left;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    margin-bottom: 5px;
    line-height: 19px !important;
}
.moreContTx{
    width: 100%;
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #868686;
    margin-bottom: 5px;
    line-height: 21px !important;
}
.moreContTx:last-child{
    margin-bottom: 0;
}
.policyContentMain{
    width: 100%;
    float: left;
}
.policyInrCont{
    width: 100%;
    float: left;
    margin-bottom: 20px;
}
.policyInrCont ul{
    padding-left: 14px;
}
.policyInrCont ul li{
    width: 100%;
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #868686;
    margin-bottom: 10px;
    line-height: 21px !important;
}
.policyInrCont div{
    width: 100% !important;
    height: auto !important;
}
.policyInrCont div iframe{
    width: 100% !important;
    height: 100% !important;
}
.policyInrCont video{
    width: 100%;
    height: auto !important;
}
.policyInrCont:last-child{
    margin-bottom: 0;
}
.faqsMain{
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.faqsMain .MuiAccordionSummary-root{
    min-height: auto;
    padding: 15px;
    /* background: #FAFAFA; */
    background-image: linear-gradient(#fff3f3, #ffc6c6);
}
.faqsMain .MuiAccordionSummary-content{
    margin: 0;
}
.faqsMain .MuiAccordionSummary-content .MuiTypography-root{
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
}
.faqsMain .MuiPaper-rounded{
    margin-bottom: 0 !important;
    border-radius: 10px;
    box-shadow: none;
    border: 1px solid #ff5555;
    overflow: hidden;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}
.faqsMain .Mui-expanded{
    margin: 0 !important;
    min-height: auto !important;
}
.faqsMain .MuiAccordionDetails-root{
    background: #fff5f5;
    padding: 15px;
    float: left;
    width: 100%;
    border-top: 2px dashed #ff0000;
}
.faqsMain .MuiTypography-root{
    font-size: 13px;
    color: #000;
    font-weight: 400;
    line-height: 21px !important;
}
.faqsMain .MuiTypography-root .moreContTx{
    font-size: 13px;
    color: #000;
    font-weight: 400;
    line-height: 21px !important;
}
.faqsMain .MuiTypography-root .policyInrCont ul{
    float: left;
    margin-bottom: 0;
    list-style-type: auto;
}
.faqsMain .MuiTypography-root .policyInrCont ul li{
    font-size: 13px;
    color: #000;
    font-weight: 400;
    line-height: 21px !important;
    margin-bottom: 10px;
}

.faqInnerUl{
    list-style-type: disc !important;
    margin-top: 15px;
}
.faqsMain .MuiTypography-root .faqInnerUl li img{
    width: 14px;
    position: relative;
    top: 3px;
}
.faqInnerUl li p{
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: flex-start;
    font-size: 13px;
    color: #000;
    font-weight: 400;
    line-height: 21px !important;
}
.faqsMain .MuiAccordionSummary-root .MuiSvgIcon-fontSizeMedium{
    color: #ff7b7b;
}




@keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }

.addSwap{
    padding-bottom: 210px;
}
.publishIcon img{
    width: 100%;
}
.prefLeft{
    width: calc(100% - 60px);
    float: left;
}
.publishIcon{
    width: 50px;
    position: absolute;
    top: 12px;
    right: 17px;
    transition: .8s;
}
.addedPublish{
    position: fixed;
    top: 50%;
    z-index: 999;
    background: #f4f4f4;
    padding: 6px;
    right: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.2);
}


/* added by vaishali for tempalte shift edit for day */
.shifViewMainForDay h1{
    border: 1px solid #ffffff !important;
    border-radius: 40px !important;
    padding: 0 10px !important;
    position: relative;
}

.locLabSt{
    position: absolute;
    font-size: 8px !important;
    top: -7px;
    left: 5px;
    background: #ffdada;
    width: auto !important;
    padding: 1px 5px;
    border-radius: 3px;
    font-weight: 600 !important;
}
.indicInTblTh{
    position: absolute;
    top: -4px;
    left: 10px;
    font-size: 8px;
    background: #000;
    color: #ff9999;
    padding: 0 4px;
    font-weight: 700;
    border-radius: 3px;
}


.editAssignedShiTime{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: .3s;
    margin-top: -60px;
    visibility: hidden;
    opacity: 0;
}
.editAssignedShiTime .rs-picker-toggle-wrapper{
    width: 100%;
}
.editAssignedShiTime .rs-input-group.rs-input-group-inside{
    border: 1px solid #ccc;
}
.timePicAction{
    display: flex;
    align-items: center;
    gap: 8px;
}
.timePicAction button{
    background: #efefef !important;
    padding: 5px;
    border-radius: 5px;
}
.forEditSft{
    display: flex;
    align-items: center;
    gap: 10px;
}
.forEditSft svg{
    font-size: 19px;
    color: #3d3e3d;
}
.ShowEditSftDiv{
    visibility: visible;
    opacity: 1;
    margin: 0;
}

.localBg{
    background: #550000;
}
.localBg2{
    background: #550000;
}
.localBg2 h3{
    color: #fff;
}
.localBg2 p{
    color: #fff;
}
.localBg2 .locLabSt{
    background: #9d2727;
    color: #fff;
}
.localTxt{
    color: #fff;
    background: #9d2727;
    font-size: 8px;
    padding: 4px 8px;
    border-radius: 11px 0;
    font-weight: 500;
    position: relative;
    top: -3px;
}
.repoLocaHead{
    font-size: 13px;
    font-weight: 700;
    color: #990000;
}



.editAssignedShiTime{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: .3s;
    margin-top: -60px;
    visibility: hidden;
    opacity: 0;
}
.editAssignedShiTime .rs-picker-toggle-wrapper{
    width: 100%;
}
.editAssignedShiTime .rs-input-group.rs-input-group-inside{
    border: 1px solid #ccc;
}
.timePicAction{
    display: flex;
    align-items: center;
    gap: 8px;
}
.timePicAction button{
    background: #efefef !important;
    padding: 5px;
    border-radius: 5px;
}
.forEditSft{
    display: flex;
    align-items: center;
    gap: 10px;
}
.forEditSft svg{
    font-size: 19px;
    color: #3d3e3d;
}
.ShowEditSftDiv{
    visibility: visible;
    opacity: 1;
    margin: 0;
}


.floating-btn {
    position: fixed !important;
    bottom: 65px;
    right: 15px;
    z-index: 9;
    background-color: #990000 !important;
    color: white !important;
    border: none !important;
    border-radius: 6px !important;
    font-size: 10px !important;
    cursor: pointer !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease !important;
    padding: 11px 11px 11px 19px !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }
  
  .floating-btn:hover {
    background-color: #e64a19;
  }

.attemTabMain{
    width: 100%;
    float: left;
}
.attemTabMain .MuiTab-textColorPrimary{
    width: auto !important;
    background: #d9d9d9;
    color: #575757;
    width: 32% !important;
    border-radius: 8px;
}
.attemTabCont{
    width: 100%;
    float: left;
    padding: 15px 0;
}
.attemTabMain .MuiTabs-scroller{
    overflow: hidden;
    margin-bottom: 0px;
    background: #e7e7e7;
    border-radius: 8px 8px;
    padding: 5px;
}
.attemTabMain .Mui-selected{
    background: #990000;
    color: #fff !important;
    border-radius: 8px;
    border: 3px solid #fff;
}
.attemTabMain .MuiTabs-flexContainer{
    gap: 5px !important;
    justify-content: space-between !important;
}
.attemTabMain .shiftOpenContScroll{
    height: calc(100vh - 265px);
}
.attemTabMain .shiftOpenCont h1{
    background: #3a3a3a;
    border-radius: 8px;
}
.skipBtn{
    position: absolute !important;
    background: #f44336 !important;
    color: #fff !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    gap: 5px;
    top: 15px;
    border-radius: 5px !important;
    font-size: 11px !important;
}
.skipBtn svg{
    font-size: 16px;
}
.scriptCount{
    position: absolute;
    background: #4caf50;
    width: 19px;
    height: 19px;
    border-radius: 100%;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    left: -8px;
    top: 7px;
    font-weight: 600;
}
.schedulConfirm{
    position: sticky !important;
    bottom: 16px !important;
    background: #4caf50 !important;
    color: #fff !important;
    text-transform: capitalize !important;
    width: 100%;
    height: 40px;
    font-size: 15px !important;
    font-weight: 600 !important;
}
.newNotiIndicat{
    position: absolute;
    top: -1px;
    right: -8px;
    background: #4caf50;
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    padding: 1px 8px;
    border-radius: 0 4px 0;
}
.newNotiIndicat::after{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 0px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid green;
    bottom: -8px;
    right: 0px;
}
.clearAllSt{
    font-size: 11px;
    white-space: nowrap;
    position: absolute;
    right: 16px;
    font-style: normal;
    font-weight: 700;
    color: #f44336 !important;
    padding: 8px;
    background: #fff3f3 !important;
}

/* Bottom Modified */
.adminBottomNavMain{
    padding: 10px;
    border: none;
}
.adminBottomNavMain .MuiBottomNavigation-root{
    border-radius: 12px;
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#990000+0,b70000+41,b20000+65,990000+100 */
background: linear-gradient(to bottom,  #990000 0%,#b70000 41%,#b20000 65%,#990000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}
.adminBottomNavMain .MuiBottomNavigationAction-root{
    color: #ff8b8b;
    transition: .3s;
}
.adminBottomNavMain .Mui-selected {
    color: #ffffff !important;
}
.adminBottomNavMain .Mui-selected svg{
    font-size: 30px;
}

























    /* Responsive */
    @media only screen and (min-width: 991px) {
        .forTabletView{}
        .forTabletView .mySlik .slick-list{
            height: auto;
        }
        .forTabletView .mySlik .slick-track{
            gap: initial;
        }
        .forTabletView .mySlik .slick-initialized .slick-slide{
            border-radius: 0;
        }
        .forTabletDate{
            display: block;
        }
        .forMobileDate{
            display: none;
        }
        .forTabletView .innerPagesMain .innerPageTopSecBackPatch {
            height: 55px;
        }
        .schedulPreviewTblMain .table-wrapper th:first-child{
            background: #333333;
            width: 125px !important;
        }
        .schedulPreviewTblMain .table-wrapper th{
            background-color: #F2F2F2;
        }
        .schedulPreviewTblMain table thead tr th p{
            color: #333;
        }
        .searchEmpTx span{
            display: block;
            font-size: 11px;
            font-weight: 400;
        }
        .forShiftsIndicat b{
            display: block;
        }
        .scheduleLocationBg{
            background-color: #171717 !important;
            color: #fff;
        }
        .forTabletView .slick-current{
            border: 1px solid #990000 !important;
        }
        .swapEmpDiv .sbIcon {
            display: block;
        }
        .forTabletView .schedulPreviewTblMain table thead tr th p{
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 7px;
        }
        .myOtpMain input {
            height: 80px !important;
            font-size: 37px !important;
        }
        .myLable {
            font-size: 18px;
        }
        .loginDay {
            font-size: 28px !important;
            margin-bottom: 19px;
        }
        .loginDatTime {
            font-size: 25px;
        }
        .emploTaskListInr h1 {
            font-size: 16px;
        }
        .tabsMainD .MuiTab-textColorPrimary {
            font-size: 17px;
        }
        .innerPageTopSec h1 {
            font-size: 18px;
        }
        .emploTaskListInr {
            padding: 20px;
        }
        .listingSearch input {
            height: 55px;
            padding: 0 20px;
        }
        .sortingIcoOut .MuiToggleButton-standard svg {
            font-size: 44px;
            color: #c5c5c5;
        }
        .tabMickMainInp input {
            padding: 0 20px;
            height: 55px;
        }
        .schedTsBt {
            font-size: 18px !important;
        }
        .myInput {
            height: 60px;
            font-size: 17px;
        }
        .myTextArea{
            font-size: 17px;
        }
        .myCheckBx .MuiTypography-root {
            font-size: 16px;
        }
        .mySwitch .MuiTypography-root{
            font-size: 19px;
        }
        .forBackArrow svg{
            font-size: 25px;
        }
        .addNewB {
            height: 50px;
        }
        .schedulAcordianTp b {
            font-size: 18px;
        }
        .prefranOrAvailab h1 {
            font-size: 17px;
            margin-bottom: 0;
        }
        .prefranOrAvailabInr p {
            font-size: 17px;
        }
        .prefranOrAvailab{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .dayWMain h1 {
            font-size: 16px;
        }
        .dayWInr span {
            font-size: 17px;
        }
        .forTabletSliderOnly .slick-current .currentWeekTx{
            display: block !important;
        }
        .employyeLisCont h1 {
            font-size: 17px;
            margin-bottom: 4px !important;
        }
        .employyeLisCont span {
            font-weight: 500;
            font-size: 15px;
        }
        .employyeLisCont p {
            font-size: 16px;
        }
        .employyeLisPic {
            width: 60px;
            min-width: 60px;
            height: 60px;
        }
        .employyeLisPic img {
            height: 60px;
        }
        .indicatAdminIco {
            width: 15px;
        }
        .employyeLisContRight span {
            font-size: 16px;
        }
        .schedularAccordianMain .MuiTypography-root {
            font-size: 16px;
        }
        .noteTs {
            font-size: 15px;
        }
        .setPrefeMain input {
            height: 50px;
            padding: 0 20px;
            font-size: 14px;
        }
        .weeklyAvailibMain label {
            font-size: 15px;
            color: #545454;
            margin-bottom: 10px;
        }
        .weeklyAvailibMain h3 {
            font-size: 16px;
            color: #333333;
        }
        marquee {
            animation: none; /* Disable animation */
            overflow: hidden; /* Stop scrolling behavior */
            white-space: normal; /* Make text wrap instead of scrolling */
          }
          .forNotiTablet .notificationMain{
            margin-top: 15px;
          }
          .forNotificationMain .slick-dots{
            top: 20px;
          }

    }


    @media only screen and (min-width: 767px) {
        .dayInD{
            padding: 13px 10px;
            font-size: 16px;
        }
        .notificationMain{
            max-width: 500px;
            display: inline-block;
            float: none;
        }
    }


    @media only screen and (min-width: 600px) {
        .addTaskNCateTabMain .MuiTabs-flexContainer {
            display: flex;
            justify-content: center;
            gap: 40px;
        }
        .addTaskNCateTabMain .schedulAcordianCont .MuiTabs-flexContainer{
            justify-content: flex-start;
        }
    }

   

